import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RootLayout from "./Layout/RootLayout";
import HomePage from "./Components/Home/Homepage";
import ServicesPage from "./Components/Services/ServicesPage";
import AboutUs from "./Components/AboutUs/AboutUs";
import { ToastContainer } from "react-toastify";
import VideoGallery from "./Components/Services/VideoCard";
import AdminLogin from "./Components/Admin/AdminLogin";
import AdminLayout from "./Layout/AdminLayout";
import AdminDashboard from "./Components/Admin/AdminDashboard";
import PartnerTable from "./Components/Tables/PartnerTable";
import AuthenticatedRoutes from "./Common/AutheticatedRoutes";
import PartnerEnquiry from "./Components/PartnerPage/PartnerEnquiery";
import AmortizationCalculator from "./Common/AmortizationCalculator";
import ContactUs from "./Components/Home/ContactUs";
import ConnectWithUs from "./Common/ConnectWithUs";

const App = () => {
  return (
    <Router>
      <ToastContainer />
      <AuthenticatedRoutes>
        <Routes>
          <Route path="/" element={<RootLayout />}>
            <Route path="" element={<HomePage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/personal-loan" element={<ServicesPage />} />
            <Route path="/business-loan" element={<ServicesPage />} />
            <Route path="/auto-loan" element={<ServicesPage />} />
            <Route path="/home-loan" element={<ServicesPage />} />
            <Route path="/gold-loan" element={<ServicesPage />} />
            <Route path="/loan-against-property" element={<ServicesPage />} />
            <Route path="/secured-loans" element={<ServicesPage />} />
            <Route path="/private-equity-funds" element={<ServicesPage />} />
            <Route path="/debentures-funds" element={<ServicesPage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/calculator" element={<AmortizationCalculator />} />
            <Route path="/video-gallery" element={<VideoGallery />} />
            <Route path="/become-partner" element={<PartnerEnquiry />} />
          </Route>
          {/* Admin Routes */}
          <Route
            path="/admin/*"
            element={
              <AdminLayout>
                <Routes>
                  <Route path="/login" element={<AdminLogin />} />
                  <Route path="/dashboard" element={<AdminDashboard />} />
                  <Route path="/partner-table" element={<PartnerTable />} />
                </Routes>
              </AdminLayout>
            }
          />
        </Routes>
        <ConnectWithUs />
      </AuthenticatedRoutes>
    </Router>
  );
};

export default App;
