import React from "react";

const QuickLauncher = () => {
  return (
    <div className="container mx-auto mt-10">
      <div className="w-full bg-greywhite sm:w-auto p-2 box-shadow flex flex-col gap-2">
        <h2 className="text-3xl font-semibold text-justify lg:main-heading">
          Want to open the gates of unlimited Earnings?{" "}
        </h2>
        <div className="flex flex-col lg:flex-row lg:items-center gap-2 w-full">
          <p className="heading">
            A personal loan is an unsecured loan that is not backed by
            collateral or security. This makes it a flexible financing option,
            as there are no limitations on its use. The followings are the ways
            a personal loan can be useful:
          </p>
          <a
            href="#"
            className="px-3 py-2 rounded-xl bg-theblack text-white w-40s flex"
          >
            Join Now{" "}
            <svg
              className="ml-1"
              fill="#FFFFFF"
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default QuickLauncher;
