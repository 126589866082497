import React, { useState } from "react";

import { PostData } from "../../utils/ApiInvoker";
import PartnerBenifits from "./PartnerBenifits";
import GenerateURL from "../../utils/ApiUrlProvider";
import Properties from "../../properties/Properties";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLoader } from "../../Common/GlobalLoader";
import ToolTip from "../../Helper/ToolTip";

const indianStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
];

const PartnerEnquiry = () => {
  const [formData, setFormData] = useState({
    name: "",
    mobile_no: "",
    email: "",
    state: "",
    city: "",
    pincode: "",
    message: "",
    category: "",
  });
  const [errors, setErrors] = useState({});

  const { showLoader, hideLoader } = useLoader();

  const validateForm = () => {
    const newErrors = {};

    // Validation for first name
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    } else if (!/^[a-zA-Z ]+$/.test(formData.name.trim())) {
      newErrors.name = "Enter a valid Name";
    }

    // Validation for mobile number
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(formData.mobile_no.trim())) {
      newErrors.mobile_no = "Enter a valid 10-digit mobile number";
    }

    // Validation for mobile number
    const pincodeRegex = /^[0-9]{6}$/;
    if (!pincodeRegex.test(formData.pincode.trim())) {
      newErrors.pincode = "Enter a valid 6-digit Pin Code";
    }

    // Validation for email
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(formData.email.trim())) {
      newErrors.email = "Enter a valid email state";
    }

    // Validation for state
    if (!formData.state.trim()) {
      newErrors.state = "State field is required";
    } else if (formData.state === "Select your state") {
      newErrors.state = "Please select a state";
    }

    // Validation for city
    if (!formData.city.trim()) {
      newErrors.city = "City field is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "name" || name === "city") {
      newValue = value.replace(/[^a-zA-Z ]/g, "");
    }

    if (name === "mobile_no" || name === "pincode") {
      newValue = value.replace(/[^0-9]/g, "");
    }

    setFormData({ ...formData, [name]: newValue });
  };

  const handleSuccess = (response) => {
    toast.success("Submitted Sucessfully, We'll Contact you soon");
    setFormData({
      name: "",
      mobile_no: "",
      email: "",
      message: "",
      category: "",
      city: "",
      state: "",
      pincode: "",
      message: "",
    });
    hideLoader();
  };

  const handleFailure = (error) => {
    if (error.mobile_no) {
      toast.error("Opps! We have a partner registered with this Mobile Number");
    } else if (error.email) {
      toast.error("Opps! We have a partner registered with this Email");
    }
    hideLoader();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isFormValid = validateForm();

    if (isFormValid) {
      showLoader();
      const url = GenerateURL({}, Properties.api.partnerEnquiery);
      // console.log(url);
      PostData(url, formData, handleSuccess, handleFailure, true);
    } else {
      hideLoader();
      console.log("Form has validation errors");
    }
  };

  return (
    <>
      <div className="container mx-auto">
        <div className="container mx-auto">
          <h2 className="text-3xl font-semibold lg:main-heading mb-6 text-center">
            Become Master Rajpal DSA's Partner
          </h2>

          <form onSubmit={handleSubmit}>
            {/* For Matching remove the Justify  */}
            <div className="flex flex-col lg:flex-row justify-center items-center">
              <div className="flex flex-col gap-4 p-5 w-full lg:w-[40%] lg:ml-5">
                <div className="flex flex-col relative">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Name"
                    className="box-shadow h-[50px] rounded-lg px-3 text-theblack/80"
                    required
                  />
                  <div class="absolute -right-6 bottom-4  shadow-md z-70">
                    <ToolTip message={"Use Alphabets Only"} />
                  </div>
                  {errors.name && <p className="text-red-500">{errors.name}</p>}
                </div>

                <div className="flex flex-col relative">
                  <input
                    type="text"
                    id="mobile_no"
                    name="mobile_no"
                    value={formData.mobile_no}
                    onChange={handleChange}
                    placeholder="Mobile Number"
                    className="box-shadow h-[50px] rounded-lg px-3 text-theblack/80"
                    required
                  />
                  <div class="absolute -right-6 bottom-4  shadow-md z-70">
                    <ToolTip message={"Enter your 10 Digit Mobile Number"} />
                  </div>
                  {errors.mobile_no && (
                    <p className="text-red-500">{errors.mobile_no}</p>
                  )}
                </div>

                <div className="flex flex-col relative">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email Address"
                    className="box-shadow h-[50px] rounded-lg px-3 text-theblack/80"
                    required
                  />
                  <div class="absolute -right-6 bottom-4  shadow-md z-70">
                    <ToolTip
                      message={`Enter Proper Email with "a", ".", and ".com"`}
                    />
                  </div>
                  {errors.email && (
                    <p className="text-red-500">{errors.email}</p>
                  )}
                </div>

                <div className="flex flex-col relative">
                  <select
                    type="text"
                    id="category"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    placeholder="category"
                    className="box-shadow h-[50px] rounded-lg px-3 text-theblack/80"
                    required
                  >
                    <option value="" disabled defaultValue>
                      Select a Service
                    </option>
                    <option value="PERSONAL_LOAN">Personal loan</option>
                    <option value="AUTO_LOAN">Auto loan</option>
                    <option value="HOME_LOAN">Home loan</option>
                    <option value="BUSINESS_LOAN">Business loan</option>
                    <option value="OTHER">Others</option>
                    {errors.category && (
                      <p className="text-red-500">{errors.category}</p>
                    )}
                  </select>
                  <div class="absolute -right-6 bottom-4  shadow-md z-70">
                    <ToolTip message={"You can Select your Desired Service"} />
                  </div>
                </div>

                {formData.category === "OTHER" && (
                  <textarea
                    id="message"
                    name="message"
                    className="box-shadow h-[50px] rounded-lg px-3 text-theblack/80"
                    placeholder="Enter your message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="2"
                    required
                  />
                )}

                <div className="flex flex-col relative">
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    placeholder="City Name"
                    className="box-shadow h-[50px] rounded-lg px-3 text-theblack/80"
                    required
                  />
                  <div class="absolute -right-6 bottom-4  shadow-md z-70">
                    <ToolTip message={"Use Alphabet Only for City Name"} />
                  </div>
                  {errors.city && <p className="text-red-500">{errors.city}</p>}
                </div>

                <div className="flex flex-col relative">
                  <select
                    type="text"
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    placeholder="State Name"
                    className="box-shadow h-[50px] rounded-lg px-3 text-theblack/80"
                    required
                  >
                    <option value="" disabled defaultValue>
                      Select your state
                    </option>
                    {indianStates.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                    {errors.state && (
                      <p className="text-red-500">{errors.state}</p>
                    )}
                  </select>
                  <div class="absolute -right-6 bottom-4  shadow-md z-70">
                    <ToolTip message={"Please Select Your State"} />
                  </div>
                </div>

                <div className="flex flex-col relative">
                  <input
                    type="number"
                    id="pincode"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    placeholder="Pin Code"
                    className="box-shadow h-[50px] rounded-lg px-3 text-theblack/80"
                    required
                  />
                  <div class="absolute -right-6 bottom-4  shadow-md z-70">
                    <ToolTip message={"Enter 6 Digit Pin Code"} />
                  </div>
                  {errors.pincode && (
                    <p className="text-red-500">{errors.pincode}</p>
                  )}
                </div>

                <button
                  className="px-3 py-2 rounded-xl bg-theblack text-white w-28 flex justify-between mt-10"
                  type="submit"
                >
                  Submit
                  <svg
                    className="ml-1"
                    fill="#FFFFFF"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  >
                    <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                  </svg>
                </button>
              </div>
              <div>
                <img
                  src="https://img.freepik.com/premium-photo/cartoon-man-suit_81048-24112.jpg?size=626&ext=jpg&ga=GA1.1.2144150192.1700322989&semt=ais"
                  alt=""
                  className="lg:ml-40"
                />
              </div>
            </div>
          </form>
        </div>
        <PartnerBenifits />
      </div>
    </>
  );
};

export default PartnerEnquiry;
