import React from "react";
import PartnerList from "../Tables/PartnerTable";
import ComingSoon from "../../Common/ComingSoon";
import DashboardHeader from "./DashboardHaeder";

const AdminDashboard = () => {
  return (
    <>
      <DashboardHeader />
      <PartnerList />
    </>
  );
};

export default AdminDashboard;
