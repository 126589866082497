import React, { useEffect } from "react";
import { IsAuthenticated } from "../utils/AccessTokenStore";
import { useNavigate } from "react-router-dom";

const AuthenticatedRoutes = (props) => {
  const navigate = useNavigate();
  const openroutes = [
    "/",
    "/services",
    "/personal-loan",
    "/business-loan",
    "/auto-loan",
    "/gold-loan",
    "/home-loan",
    "/secured-loans",
    "/private-equity-funds",
    "/loan-against-property",
    "/debentures-funds",
    "/about-us",
    "/contact-us",
    "/calculator",
    "/video-gallery",
    "/become-partner",
    "/admin/login",
  ];

  const checkAuth = () => {
    const url = window.location.pathname;
    if (IsAuthenticated()) {
      return;
      // if (IsSuperUser()) {
      // } else if (IsNormalUser()) {
      //   if (url.startsWith("/admin")) {
      //     navigate("/user/dashboard");
      //   } else if (url.startsWith("/user")) {
      //     return;
      //   }
      // }
    } else if (!IsAuthenticated()) {
      if (!openroutes.includes(url)) {
        navigate("/", { replace: true });
      } else {
        return;
      }
    } else {
      navigate("/");
    }
  };
  useEffect(() => {
    checkAuth();
  }, [navigate]);
  return IsAuthenticated() || openroutes.includes(window.location.pathname) ? (
    <div>{props.children}</div>
  ) : (
    <></>
  );
};

export default AuthenticatedRoutes;
