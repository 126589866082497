import React, { useEffect, useState } from "react";
import logo from "../../Assets/logo.png";
import { useLocation, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const [dynamicLink, setDynamicLink] = useState("/");
  const [dynamicHeading, setDynamicHeading] = useState("Home");
  const [selectedMenu, setSelectedMenu] = useState("/");

  const location = useLocation();
  const navigate = useNavigate();

  const changeVisible = () => {
    setIsMenuVisible((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMenuVisible(window.innerWidth > 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const { pathname } = location;
    setDynamicLink(pathname);
    setDynamicHeading(getDynamicHeading(pathname));
    setSelectedMenu(pathname);
  }, [location]);

  const getDynamicHeading = (pathname) => {
    switch (pathname) {
      case "/about-us":
        return "About";
      case "/personal-loan":
        return "Personal Loan";
      case "/loan":
        return "Loan";
      case "/calculator":
        return "Calculator";
      case "/contact-us":
        return "Contact Us";
      case "/become-partner":
        return "Become a Partner";
      default:
        return "Home";
    }
  };

  return (
    <>
      <nav className="w-full fixed top-0 left-0 z-10  shadow-md bg-white">
        <div className="container  m-auto flex flex-col md:flex-row items-center justify-between py-3">
          <div className="flex flex-row w-full md:w-auto justify-between px-4 md:px-0">
            <a href="/" className="text-lg font-bold">
              <img
                className="h-10 aspect-square"
                src={logo}
                alt="mrrajpaldsa logo"
              />
            </a>
            <button className="md:hidden" onClick={changeVisible}>
              <img
                className="h-8 w-8"
                src="https://cdn-icons-png.flaticon.com/128/6015/6015685.png"
                alt="starops menue"
              />
            </button>
          </div>

          {isMenuVisible && (
            <ul className="grid grid-cols-1 gap-1 place-items-center  md:flex flex-row md:space-x-4 w-full  justify-end ">
              <li>
                <a
                  href="/"
                  className={
                    selectedMenu === "/"
                      ? "font-bold border-b-2 border-black"
                      : ""
                  }
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/about-us"
                  className={
                    selectedMenu === "/about-us"
                      ? "font-bold border-b-2 border-black"
                      : ""
                  }
                >
                  About
                </a>
              </li>

              <li className="group">
                <button
                  id="dropdownAboutLink"
                  className="flex items-center justify-between w-full py-2 pl-3 pr-4 text-the-black rounded  md:hover-bg-transparent md:border-0 md:hover-text-blue-600 md:p-0 md:w-auto group-hover:hover:text-theblack/80"
                >
                  Services
                  <svg
                    className="w-2.5 h-2.5 ml-2.5 rotate-element transform group-hover:rotate-180 transition duration-300"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>

                <div
                  id="dropdownAbout"
                  className="absolute z-10 hidden group-hover:block font-normal bg-white divide-y divide-gray-100 rounded-lg shadow  group"
                >
                  <div className="flex flex-row w-max">
                    <ul className="py-2 text-sm text-gray-700 ">
                      <li>
                        <div
                          onClick={() => navigate("/personal-loan")}
                          className="block px-4 py-2  hover:bg-gray-50 hover:text-theblack/80 cursor-pointer"
                        >
                          Personal Loan
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => navigate("/home-loan")}
                          className="block px-4 py-2  hover:bg-gray-50 hover:text-theblack/80 cursor-pointer"
                        >
                          Home Loan
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => navigate("/business-loan")}
                          className="block px-4 py-2  hover:bg-gray-50 hover:text-theblack/80 cursor-pointer"
                        >
                          Business Loan
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => navigate("/auto-loan")}
                          className="block px-4 py-2  hover:bg-gray-50 hover:text-theblack/80 cursor-pointer"
                        >
                          Auto Loan
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => navigate("/debentures-funds")}
                          className="block px-4 py-2  hover:bg-gray-50 hover:text-theblack/80 cursor-pointer"
                        >
                          Debentures Loan
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => navigate("/private-equity-funds")}
                          className="block px-4 py-2  hover:bg-gray-50 hover:text-theblack/80 cursor-pointer"
                        >
                          Private Equity
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => navigate("/gold-loan")}
                          className="block px-4 py-2  hover:bg-gray-50 hover:text-theblack/80 cursor-pointer"
                        >
                          Gold Loan
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="group">
                <button
                  id="dropdownAboutLink"
                  className="flex items-center justify-between w-full py-2 pl-3 pr-4 text-the-black rounded  md:hover-bg-transparent md:border-0 md:hover-text-blue-600 md:p-0 md:w-auto group-hover:hover:text-theblack/80"
                >
                  Loans
                  <svg
                    className="w-2.5 h-2.5 ml-2.5 rotate-element transform group-hover:rotate-180 transition duration-300"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>
                <div
                  id="dropdownAbout"
                  className="absolute z-10 hidden group-hover:block font-normal bg-white divide-y divide-gray-100 rounded-lg shadow  group"
                >
                  <div className="flex flex-row w-max">
                    <ul className="py-2 text-sm text-gray-700 ">
                      <li>
                        <div
                          onClick={() => navigate("/personal-loan")}
                          className="block px-4 py-2  hover:bg-gray-50 hover:text-theblack/80 cursor-pointer"
                        >
                          Personal Loan
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => navigate("/home-loan")}
                          className="block px-4 py-2  hover:bg-gray-50 hover:text-theblack/80 cursor-pointer"
                        >
                          Home Loan
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => navigate("/business-loan")}
                          className="block px-4 py-2  hover:bg-gray-50 hover:text-theblack/80 cursor-pointer"
                        >
                          Business Loan
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => navigate("/auto-loan")}
                          className="block px-4 py-2  hover:bg-gray-50 hover:text-theblack/80 cursor-pointer"
                        >
                          Auto Loan
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => navigate("/debentures-funds")}
                          className="block px-4 py-2  hover:bg-gray-50 hover:text-theblack/80 cursor-pointer"
                        >
                          Debentures Loan
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => navigate("/private-equity-funds")}
                          className="block px-4 py-2  hover:bg-gray-50 hover:text-theblack/80 cursor-pointer"
                        >
                          Private Equity
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => navigate("/gold-loan")}
                          className="block px-4 py-2  hover:bg-gray-50 hover:text-theblack/80 cursor-pointer"
                        >
                          Gold Loan
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <a
                  href="/calculator"
                  className={
                    selectedMenu === "/calculator"
                      ? "font-bold border-b-2 border-black"
                      : ""
                  }
                >
                  Calculator
                </a>
              </li>
              <li>
                <a
                  href="/video-gallery"
                  className={
                    selectedMenu === "/video-gallery"
                      ? "font-bold border-b-2 border-black"
                      : ""
                  }
                >
                  Videos
                </a>
              </li>
              <li>
                <a
                  href="/contact-us"
                  className={
                    selectedMenu === "/contact-us"
                      ? "font-bold border-b-2 border-black"
                      : ""
                  }
                >
                  Contact Us
                </a>
              </li>
              <li>
                <a
                  href="/become-partner"
                  className={`px-3 py-2 rounded-xl bg-theblack text-white ${
                    selectedMenu === "/become-partner"
                      ? "font-bold border-b-2 border-black"
                      : ""
                  }`}
                >
                  Become a partner
                </a>
              </li>
            </ul>
          )}
        </div>
      </nav>
      {dynamicLink !== "/" && (
        <div className="container mx-auto mt-20">
          <a href="/">
            Home <span className="">/ </span>
          </a>
          <a className="font-semibold" href={dynamicLink}>
            {dynamicHeading}
          </a>
        </div>
      )}
    </>
  );
};

export default Navbar;
