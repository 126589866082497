import React from "react";

const PartnerBenifits = () => {
  return (
    <div className="py-5 my-5" id="eligibility">
      <div className="container mx-auto mt-10">
        <h2 className="text-3xl font-semibold lg:main-heading mb-6 text-center">
          Benfits of Becoming Master Rajpal DSA's Partner
        </h2>
        <div className="flex flex-col md:flex-row items-center justify-between gap-3">
          {/* Box 1 */}
          <div className="w-full  sm:w-1/2 p-5 mb-5 sm:mb-0 bg-white box-shadow rounded-md relative ">
            <h3 className="secondary-heading mb-2 text-center">
              Lucrative Commission Structures:
            </h3>
            <div className="w-10 h-1 bg-white mx-auto mb-4"></div>
            <ul className="list-disc pl-6">
              <li className="heading">
                Competitive Rates: Enjoy industry-leading commission rates,
                ensuring your efforts are generously rewarded.
              </li>
              <li className="heading">
                Tiered Incentives: Progress through tiered incentive programs
                for escalating rewards as your performance levels soar.
              </li>
              <li className="heading">
                Transparent Payouts: Experience clarity with transparent
                commission payouts, fostering trust in our partnership.
              </li>
              <li className="heading">
                Flexible Payment Options: Choose from various payment options,
                providing flexibility to align with your preferences.
              </li>
            </ul>
          </div>
          {/* Box 2 */}
          <div className="w-full  sm:w-1/2 p-5 mb-5 sm:mb-0 bg-white box-shadow rounded-md relative">
            <h3 className="secondary-heading mb-2 text-center">
              Comprehensive Training and Support:
            </h3>
            <div className="w-10 h-1 bg-white mx-auto mb-4"></div>
            <ul className="list-disc pl-6">
              <li className="heading">
                Specialized Training: Access specialized programs to enhance
                your knowledge and skills for effective promotion of our diverse
                loan services.
              </li>
              <li className="heading">
                Dedicated Support Team: Rely on a dedicated team for prompt
                assistance, guidance, and resolution of queries.
              </li>
              <li className="heading">
                Marketing Collateral Support: Receive professionally crafted
                marketing collateral to boost your promotional efforts.
              </li>
              <li className="heading">
                Regular Updates: Stay informed with regular updates and
                resources, ensuring you are well-prepared to navigate the
                dynamic financial services landscape.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerBenifits;
