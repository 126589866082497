import React, { useState } from "react";
import logo from "../../Assets/logo.png";
import { useLoader } from "../../Common/GlobalLoader";
import { toast } from "react-toastify";
import GenerateURL from "../../utils/ApiUrlProvider";
import properties from "../../properties/Properties";
import { PostData } from "../../utils/ApiInvoker";
import { useNavigate } from "react-router-dom";
import { PersistLoginCreds } from "../../utils/AccessTokenStore";
import OpenEye from "../../Assets/OpenEye.png";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  const { showLoader, hideLoader } = useLoader();

  const validateForm = () => {
    const newErrors = {};

    // Validation for email
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(formData.email.trim())) {
      newErrors.email = "Enter a valid email";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLoginSucess = (response) => {
    PersistLoginCreds(response);
    navigate("/admin/dashboard");
    toast.success("Logged In!", {
      autoClose: 1000,
    });
    hideLoader();
  };

  const handleLoginFailure = (error) => {
    hideLoader();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isFormValid = validateForm();

    if (isFormValid) {
      showLoader();
      const url = GenerateURL({}, properties.api.adminLogin);
      PostData(url, formData, handleLoginSucess, handleLoginFailure, true);
    } else {
      toast.error("Something went Wrong");
      hideLoader();
    }
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <div className="flex flex-col lg:flex-row items-center justify-between w-full lg:w-1/2 box-shadow mt-[10%] bg-[#f9f9f9]">
          <div className="flex flex-col items-center justify-center gap-5 w-full lg:w-1/2">
            <img
              src={logo}
              alt="About mr rajpaldsa "
              className="aspect-square max-w-[100px] sm:max-w-[250px] w-full sm:w-full blend-overlay"
            />
            <p className="text-center lg:mt-5 font-semibold text-lg border-b-2 border-theblack ">
              Welcome to Admin Dashboard
            </p>
          </div>
          <div className="flex flex-col items-center justify-between w-full lg:w-1/2">
            <div className="relative bg-white/10 flex flex-col items-center justify-center gap-3 w-full p-4">
              <h1 className="text-3xl font-semibold lg:main-heading text-center mt-10">
                Login
              </h1>
              <p className="text-center font-semibold">
                Please Enter your Email and Password
              </p>
              <div className="flex flex-col gap-5 w-full  mt-10">
                <div className="flex flex-col gap-2">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    className="box-shadow h-[50px] rounded-lg px-3 text-theblack/80"
                    required
                  />
                  {errors.email && (
                    <p className="text-red-500">{errors.email}</p>
                  )}
                </div>

                <div className="flex flex-col gap-2 relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Password"
                    className="box-shadow h-[50px] rounded-lg px-3 text-theblack/80"
                    required
                  />
                  <img
                    src={OpenEye}
                    alt="Toggle Password Visibility"
                    className="absolute cursor-pointer right-0 top-1/2 transform -translate-y-1/2 w-6 h-6 opacity-70"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
              </div>
              <button
                className="px-3 py-2 rounded-xl bg-theblack text-white w-28 flex justify-between mt-20"
                type="submit"
                onClick={handleSubmit}
              >
                Submit
                <svg
                  className="ml-1"
                  fill="#FFFFFF"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  clipRule="evenodd"
                >
                  <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
