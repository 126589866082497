import React from "react";

const WhyChooseUsCard = ({ data }) => {
  return (
    <div className="border h-96 lg:h-full rounded-md shadow-md py-6 px-4 flex flex-col items-center justify-between gap-1">
      <img
        className="h-20 aspect-square mt-3"
        src={data.imageUrl}
        alt="loan icon"
      />
      <h1 className="secondary-heading text-center">{data.heading}</h1>
      <p className="heading mb-4   pt-2 text-center">{data.description}</p>
    </div>
  );
};

export default WhyChooseUsCard;
