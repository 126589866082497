import React from "react";

const Features = ({ featuresData }) => {
  if (!featuresData) {
    return null; // Return null or handle invalid data as needed
  }

  const { title, list } = featuresData;
  return (
    <div className="py-5 my-5" id="features">
      <div className="container bg-greywhite box-shadow mx-auto mt-10">
        <h2 className="text-3xl font-semibold lg:main-heading mb-6 text-center">
          Apply For {title}
        </h2>
        <div className="w-full sm:w-auto p-5">
          <p className="heading">
            A personal loan is an unsecured loan that is not backed by
            collateral or security. This makes it a flexible financing option,
            as there are no limitations on its use. The followings are the ways
            a personal loan can be useful:
          </p>
          <ul className="list-disc pl-6 mt-4 heading">
            {list.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Features;
