import React, { useEffect, useRef } from "react";

const ModalWindow = ({ onClose, title, children }) => {
  const modalRef = useRef();
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };
  const handleInteractionInsideModal = (event) => {
    event.stopPropagation();
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      className="fixed overflow-auto inset-0 flex items-center justify-center bg-theblack/40"
      onClick={onClose}
    >
      <div
        className="w-11/12 md:w-4/6 lg:w-1/2 xl:w-[40%] flex flex-col gap-4 bg-white z-70 box-shadow p-4"
        onClick={handleInteractionInsideModal}
        ref={modalRef}
      >
        <div className="flex flex-row justify-between border-b-2">
          <h1 className="text-[28px] font-medium text-[#3e3e3e]">{title}</h1>
          <div className="flex flex-row relative">
            <button className="hover:scale-110">
              <img
                src="https://cdn-icons-png.flaticon.com/128/2997/2997911.png"
                alt=""
                className="h-4"
                onClick={onClose}
              />
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <>{children}</>
        </div>
      </div>
    </div>
  );
};

export default ModalWindow;
