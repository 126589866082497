import properties from "../properties/Properties";
import { GetData } from "../utils/ApiInvoker";
import GenerateURL from "../utils/ApiUrlProvider";

export function ConvertBoolean(input) {
  if (typeof input === "boolean") {
    return input;
  } else if (typeof input === "string") {
    const lowerInput = input.toLowerCase();
    if (lowerInput === "yes") {
      return true;
    } else if (lowerInput === "no") {
      return false;
    } else if (lowerInput === "true") {
      return true;
    } else if (lowerInput === "false") {
      return false;
    }
  } else if (typeof input === "number") {
    return input > 0;
  }
  return false;
}

const HandleFileDownlaodSucess = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  // Create a link element
  const a = document.createElement("a");
  a.href = url;
  a.download = `${fileName}`; // Set the desired file name and extension
  document.body.appendChild(a);
  // Trigger the click event on the link to initiate download
  a.click();
  // Cleanup: remove the link and revoke the Blob URL after download
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};
const HandleFileDownlaodError = (err) => {
  console.log(err);
};

export const GlobalFileDownlad = (fileInfo) => {
  const url = GenerateURL(
    { file_unique_id: fileInfo.unique_id },
    properties.api.fileDownload
  );
  GetData(
    url,
    (blob) => HandleFileDownlaodSucess(blob, fileInfo.file_name),
    HandleFileDownlaodError,
    false,
    false
  );
};
export const GlobalFileDownladbyID = (id, name) => {
  const url = GenerateURL({ file_unique_id: id }, properties.api.fileDownload);
  GetData(
    url,
    (blob) => HandleFileDownlaodSucess(blob, name),
    HandleFileDownlaodError,
    false,
    false
  );
};
export const GetTimeAgo = (timestamp) => {
  const currentDate = new Date();
  const pastDate = new Date(timestamp);
  const timeDifference = currentDate - pastDate;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0) {
    return `${years} year${years > 1 ? "s" : ""} ago`;
  } else if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  }
};
export const GetDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const StatusTag = (ack) => {
  if (ack === false) {
    return (
      <span className="inline-flex items-center bg-PendingStatus/10 text-PendingStatus text-xs font-medium px-2.5 py-0.5 rounded-lg">
        <span className="w-2 h-2 me-1 bg-PendingStatus rounded-full"></span>
        No
      </span>
    );
  } else if (ack === true) {
    return (
      <span className="inline-flex items-center bg-SuccessStatus/10 text-SuccessStatus text-xs font-medium px-2.5 py-0.5 rounded-lg">
        <span className="w-2 h-2 me-1 bg-SuccessStatus rounded-full"></span>
        Yes
      </span>
    );
  } else {
    return (
      <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-lg">
        <span className="w-2 h-2 me-1 bg-red-500 rounded-full"></span>
        {ack}
      </span>
    );
  }
};

export function GetHeadingByid(id) {
  const loanOptions = [
    {
      id: 1000,
      name: "Personal Loan (Salaried)",
    },
    {
      id: 1100,
      name: "Personal Loan (Self Employed)",
    },
    {
      id: 1200,
      name: "Auto Loan (Salaried)",
    },
    {
      id: 1300,
      name: "Auto Loan (Self Employed)",
    },
    {
      id: 1400,
      name: "Home Loan (Salaried)",
    },
    {
      id: 1500,
      name: "Home Loan (Self Employed)",
    },
    {
      id: 1600,
      name: "Business Loan",
    },
    {
      id: 1700,
      name: "Loan Against Property",
    },
    {
      id: 1800,
      name: "Gold Loan",
    },
    {
      id: 1900,
      name: "Other Loan (Query)",
    },
    {
      id: 2000,
      name: "Health Insurance",
    },
    {
      id: 2100,
      name: "Car Insurance",
    },
    {
      id: 2200,
      name: "Bike Insurance",
    },
    {
      id: 2300,
      name: "Term Life Insurance",
    },
    {
      id: 3000,
      name: "Credit Card",
    },
    {
      id: 4000,
      name: "LIC Plans",
    },
    {
      id: 4100,
      name: "Mutual Funds",
    },
  ];

  const foundLoan = loanOptions.find((option) => option.id === parseInt(id));
  return foundLoan ? foundLoan.name : "Application form";
}
