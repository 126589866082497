import React from "react";

const PartnersIconCard = ({ data }) => {
  return (
    <>
      <div className="border h-16 w-36 rounded-md px-2  md:w-44 box-shadow hover:scale-110 hover:shadow-lg border-logo-blue border-opacity-30">
        <img
          className="h-full w-full p-3 blend-overlay"
          src={data}
          alt="partner_logo"
        />
      </div>
    </>
  );
};

export default PartnersIconCard;
