import React from "react";

const ToolTip = ({ message }) => {
  return (
    <div className="relative block w-4 mt-1 group">
      <img
        src="https://cdn-icons-png.flaticon.com/128/471/471662.png"
        alt=""
        className="w-full aspect-square"
      />
      <div className="hidden group-hover:block absolute bottom-0 transform -translate-y-4 w-36 bg-theblack text-white text-xs px-2 py-1 rounded opacity-100 transition-opacity duration-300">
        <p>{message || ""}</p>
      </div>
    </div>
  );
};

export default ToolTip;
