import React, { useState } from "react";

const FAQ = () => {
  const faqData = [
    {
      question: "What types of loans does MasterRajPal DSA offer?",
      answer:
        "MasterRajPal DSA offers a comprehensive suite of loan products tailored to meet diverse financial needs. This includes personalized loans for individual requirements, business funding to support entrepreneurial ventures, home mortgages for property purchases, and auto loans for vehicle financing. Our goal is to provide a range of options to address various financial goals and circumstances.",
    },
    {
      question:
        "What are the eligibility criteria for obtaining a loan from MasterRajPal DSA?",
      answer:
        "Eligibility criteria vary depending on the type of loan. Generally, factors such as credit score, income stability, employment history, and debt-to-income ratio play a crucial role. Specifics may include a minimum credit score, a certain level of income, and a clean credit history. We encourage potential applicants to review our eligibility criteria for each loan type to determine their qualification status.",
    },
    {
      question:
        "How is the interest rate determined on loans from MasterRajPal DSA?",
      answer:
        "Interest rates at MasterRajPal DSA are determined based on a combination of factors. These may include the applicant's creditworthiness, prevailing market rates, loan amount, and the chosen repayment period. Applicants with higher credit scores often qualify for more favorable interest rates, reflecting lower perceived risk. Our aim is to provide transparent and competitive rates to ensure affordability for our borrowers.",
    },
    {
      question:
        "What documents are required for a loan application with MasterRajPal DSA?",
      answer:
        "The documentation requirements vary for each loan type but commonly include proof of identity (such as a government-issued ID), proof of income (pay stubs, tax returns), employment verification, and details about the purpose of the loan. For mortgage or property-related loans, property documents may also be necessary. A comprehensive list of required documents is provided during the application process to facilitate a smooth and efficient experience.",
    },
    {
      question: "What is the loan approval timeline at MasterRajPal DSA?",
      answer:
        "The loan approval timeline depends on various factors, including the complexity of the application, the completeness of documentation, and the efficiency of the verification process. Generally, our goal is to process applications promptly. On average, applicants can expect to receive a decision within a few days to a couple of weeks. We prioritize a swift and transparent approval process to meet our customers' financial needs in a timely manner.",
    },
    {
      question:
        "Are there any prepayment penalties or fees with MasterRajPal DSA?",
      answer:
        "MasterRajPal DSA believes in empowering our customers to manage their finances responsibly. As such, we do not impose prepayment penalties on our loans. Borrowers are encouraged to pay off their loans early if they wish, saving on interest costs. We do not charge hidden fees, and all applicable fees are transparently communicated during the loan origination process. We are committed to ensuring that our borrowers have a clear understanding of the costs associated with their loans.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="py-5 my-5" id="faq">
      <div className="container mx-auto mt-10">
        <h2 className="text-3xl font-semibold lg:main-heading mb-6 text-center">
          Your Most Asked
        </h2>
        <div className="container">
          {faqData.map((item, index) => (
            <div key={index} className="mb-4">
              <div
                className="flex justify-between cursor-pointer box-shadow2 px-4 pt-4 rounded-t-lg bg-white"
                onClick={() => toggleAccordion(index)}
              >
                <h3 className="text-xl font-semibold mb-6 lg:secondary-heading">
                  {item.question}
                </h3>
                <div>{activeIndex === index ? "-" : "+"}</div>
              </div>
              {activeIndex === index && (
                <div className=" bg-[#cecece] p-4 rounded-b-lg box-shadow2">
                  <p className="text-lg lg:heading">{item.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
