import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/logo.png";
import ModalWindow from "../../Helper/ModalWindow";
import { toast } from "react-toastify";
import properties from "../../properties/Properties";
import GenerateURL from "../../utils/ApiUrlProvider";
import { PutData } from "../../utils/ApiInvoker";
import { GetAuth } from "../../utils/AccessTokenStore";
import ToolTip from "../../Helper/ToolTip";
import OpenEye from "../../Assets/OpenEye.png";

const DashboardHeader = (props) => {
  const navigate = useNavigate();
  const profile = GetAuth();
  const [isActive, setIsActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  //Modal For Edit
  const initialValue = {
    first_name: "",
    last_name: "",
    email: profile.email || "",
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

  const initialErrors = {
    first_name: "",
    last_name: "",
    email: "",
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

  const [formData, setFormData] = useState(initialValue);
  const [errors, setErrors] = useState(initialErrors);

  const toggleEditModal = () => {
    setIsModalOpen(!isModalOpen);
    setFormData(initialValue);
    setErrors(initialErrors);
  };

  const validateForm = () => {
    const newErrors = {};

    // Validation for first name
    if (!formData.first_name.trim()) {
      newErrors.first_name = "First Name is required";
    } else if (!/^[a-zA-Z ]+$/.test(formData.first_name.trim())) {
      newErrors.first_name = "Enter a valid Name";
    }

    // Validation for last name
    if (!formData.last_name.trim()) {
      newErrors.last_name = "Last Name is required";
    } else if (!/^[a-zA-Z ]+$/.test(formData.last_name.trim())) {
      newErrors.last_name = "Enter a valid Name";
    }

    // Validation for email
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(formData.email.trim())) {
      newErrors.email = `Enter a valid email including "a", "." and  "@"`;
    }

    if (formData.new_password !== formData.confirm_password) {
      newErrors.confirm_password = "Passwords do not match";
    }

    if (!formData.old_password.trim()) {
      newErrors.old_password = "Old Password is required";
    }
    if (!formData.new_password.trim()) {
      newErrors.new_password = "New Password is required";
    }
    if (!formData.confirm_password.trim()) {
      newErrors.confirm_password = "Confirm Password to Proceed";
    }

    setErrors((prev) => ({ ...prev, ...newErrors }));

    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "first_name" || name === "last_name") {
      newValue = value.replace(/[^a-zA-Z ]/g, "");
    }

    setErrors({ ...errors, [name]: "" });
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSuccess = (response) => {
    toast.success(response.message);
    setIsModalOpen(false);
  };

  const handleFailure = (error) => {
    toast.error(error.message);
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isFormValid = validateForm();

    if (isFormValid) {
      const url = GenerateURL({}, properties.api.adminInfoUpdate);
      const data = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        old_password: formData.old_password,
        new_password: formData.new_password,
      };
      PutData(url, data, handleSuccess, handleFailure, true);
    } else {
      console.log("Form has validation errors");
    }
  };

  //Header Setup

  const redirectToHomePage = () => {
    navigate("/");
  };

  useEffect(() => {}, [isModalOpen]);

  const initiateLogout = () => {
    localStorage.removeItem("authMasterRajpalDSAData");
    navigate("/admin/login");
  };

  const toggleDropdown = () => {
    setIsActive((prevState) => !prevState);
  };

  const handleMouseLeave = () => {
    if (!isActive) {
      setIsActive(false);
    }
  };

  return (
    <>
      <div className="fixed w-full z-50">
        <div className="flex-col flex">
          <div className="w-full border-b-2 border-gray-200">
            <div className="bg-white h-16 justify-between items-center mx-auto px-2 md:px-4 flex">
              <div>
                <img
                  src={logo}
                  className="block h-9 w-auto cursor-pointer"
                  alt="udharibazar"
                  onClick={() => redirectToHomePage()}
                />
              </div>
              <div className="group relative py-10">
                <div
                  className="md:space-x-6 justify-end items-center ml-auto flex space-x-3 cursor-pointer  min-w-[150px]"
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="justify-center items-center flex relative">
                    {profile && (
                      <p className="block py-2 pl-3 pr-4 text-gray-900 rounded font-semibold  hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-600 md:p-0">
                        {profile.email || "--"}
                      </p>
                    )}
                    <svg
                      className="w-2.5 h-2.5 ml-2.5 rotate-element transform group-hover:rotate-180 transition duration-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                      onClick={toggleDropdown}
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </div>

                  <div
                    className={`absolute ${
                      isActive ? "block" : "hidden"
                    } group-hover:block  hover:block  w-full bottom-0 translate-y-16 bg-white rounded-md`}
                  >
                    <div className="container rounded-md text-left md:w-auto shadow-lg">
                      <ul className="py-1 text-sm text-gray-700 w-full ">
                        <li className="flex flex-row align-items-center w-full">
                          <button
                            disabled={props.isProfileDisabled}
                            className={`${
                              props.isProfileDisabled
                                ? " hover:bg-gray-50 hover:text-gray-400 hover:cursor-not-allowed"
                                : "hover:bg-gray-50 hover:text-blue-600"
                            } block px-4 py-2  w-full text-left`}
                            onClick={() => {
                              setIsModalOpen(true);
                            }}
                          >
                            Profile
                          </button>
                        </li>
                        <li className="flex flex-row align-items-center ">
                          <button
                            className="block px-4 py-2 hover:bg-gray-50 hover:text-blue-600 w-full text-left"
                            onClick={initiateLogout}
                          >
                            Logout
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && (
          <ModalWindow onClose={toggleEditModal} title="Edit Your Info">
            <div className="flex flex-col md:flex-row md:items-center md:justify-center gap-2">
              <h1 className="w-full md:w-[25%] ">First Name</h1>
              <div className="flex flex-col gap-2 w-full md:w-3/4">
                <div className="flex flex-row gap-1 items-center justify-center ">
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    placeholder="First Name"
                    className="shadow-md h-[40px] rounded-lg px-2 text-theblack/80 w-full"
                    required
                  />
                  <ToolTip message={"Use Alphabets Only"} />
                </div>
                {errors.first_name && (
                  <p className="text-red-500 w-full px-2">
                    {errors.first_name}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:items-center md:justify-center gap-2">
              <h1 className="w-full md:w-[25%] ">Last Name</h1>
              <div className="flex flex-col gap-2 w-full md:w-3/4">
                <div className="flex flex-row gap-1 items-center justify-center">
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    placeholder="Last Name"
                    className="shadow-md h-[40px] rounded-lg px-2 text-theblack/80 w-full"
                    required
                  />
                  <ToolTip message={"Use Alphabets Only"} />
                </div>
                {errors.last_name && (
                  <p className="text-red-500 w-full px-2">{errors.last_name}</p>
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:items-center md:justify-center gap-2">
              <h1 className="w-full md:w-[25%] ">Email</h1>
              <div className="flex flex-col gap-2 w-full md:w-3/4">
                <div className="flex flex-row gap-1 items-center justify-center">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email Address"
                    className="shadow-md h-[40px] rounded-lg px-2 text-theblack/80 w-full"
                    disabled
                  />
                  <ToolTip message={"Email Cannot Changed"} />
                </div>
                {errors.email && (
                  <p className="text-red-500 w-full px-2">{errors.email}</p>
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:items-center md:justify-center gap-2">
              <h1 className="w-full md:w-[25%] ">Old Password</h1>
              <div className="flex flex-col gap-2 w-full md:w-3/4">
                <div className="flex flex-row gap-1 items-center justify-center relative">
                  <input
                    type={showOldPassword ? "text" : "password"}
                    id="old_password"
                    name="old_password"
                    value={formData.old_password}
                    onChange={handleChange}
                    placeholder="Enter your Current Password"
                    className="shadow-md h-[40px] rounded-lg px-2 text-theblack/80 w-full"
                    required
                  />
                  <img
                    src={OpenEye}
                    alt="Toggle Password Visibility"
                    className="absolute cursor-pointer right-5 top-1/2 transform -translate-y-1/2 w-6 h-6 opacity-70"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  />
                  <ToolTip message={"Enter Your Current Password"} />
                </div>
                {errors.old_password && (
                  <p className="text-red-500 w-full px-2">
                    {errors.old_password}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:items-center md:justify-center gap-2">
              <h1 className="w-full md:w-[25%] ">New Password</h1>
              <div className="flex flex-col gap-2 w-full md:w-3/4">
                <div className="flex flex-row gap-1 items-center justify-center relative">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    id="new_password"
                    name="new_password"
                    value={formData.new_password}
                    onChange={handleChange}
                    placeholder="Enter your New Password"
                    className="shadow-md h-[40px] rounded-lg px-2 text-theblack/80 w-full"
                    required
                  />
                  <img
                    src={OpenEye}
                    alt="Toggle Password Visibility"
                    className="absolute cursor-pointer right-5 top-1/2 transform -translate-y-1/2 w-6 h-6 opacity-70"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  />
                  <ToolTip message={"Choose a Strong Password"} />
                </div>
                {errors.new_password && (
                  <p className="text-red-500 w-full px-2">
                    {errors.new_password}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:items-center md:justify-center gap-2">
              <h1 className="w-full md:w-[25%] ">Confirm Password</h1>
              <div className="flex flex-col gap-2 w-full md:w-3/4">
                <div className="flex flex-row gap-1 items-center justify-center relative">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirm_password"
                    name="confirm_password"
                    value={formData.confirm_password}
                    onChange={handleChange}
                    placeholder="Confirm New Password"
                    className="shadow-md h-[40px] rounded-lg px-2 text-theblack/80 w-full"
                    required
                  />
                  <img
                    src={OpenEye}
                    alt="Toggle Password Visibility"
                    className="absolute cursor-pointer right-5 top-1/2 transform -translate-y-1/2 w-6 h-6 opacity-70"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                  <ToolTip message={"Confirm Your Password"} />
                </div>
                {errors.confirm_password && (
                  <p className="text-red-500 w-full px-2">
                    {errors.confirm_password}
                  </p>
                )}
              </div>
            </div>
            <div className="flex justify-center pr-4 ">
              <button
                className="px-2 py-1 rounded-lg bg-white  border border-theblack/40  text-theblack hover:bg-theblack hover:text-white"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </ModalWindow>
        )}
      </div>
    </>
  );
};

export default DashboardHeader;
