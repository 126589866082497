import React from "react";

const StickyNavbar = () => {
  return (
    <div className="fixed flex-col z-50 hidden lg:block">
      <a
        href="https://www.instagram.com/masterrajpaldsapvtltd/"
        target="_blank"
        className="social-icon bg-[#d62976] p-1 fixed  right-0 top-[40%]"
      >
        <img
          src="https://cdn-icons-png.flaticon.com/128/1409/1409946.png"
          alt="Instagram"
          className="w-8 h-8 transition-transform transform hover:scale-110"
        />
      </a>
      <a
        href="https://www.facebook.com/MASTERRAJPALDSAPVTLTD"
        target="_blank"
        className="social-icon bg-[#1877F2] p-1 fixed right-0 top-[45%]"
      >
        <img
          src="https://cdn-icons-png.flaticon.com/128/2504/2504903.png"
          alt="Facebook"
          className="w-8 h-8 transition-transform transform hover:scale-110"
        />
      </a>
      <a
        href="https://www.youtube.com/@masterrajpaldsapvtltd"
        target="_blank"
        className="social-icon bg-[#FF0000] p-1 fixed  right-0 top-[50%]"
      >
        <img
          src="https://cdn-icons-png.flaticon.com/128/2504/2504965.png"
          alt="YouTube"
          className="w-8 h-8 transition-transform transform hover:scale-110"
        />
      </a>
      <a
        href="https://twitter.com/masterrajpaldsa"
        target="_blank"
        className="social-icon bg-[#1DA1F2] p-1 fixed  right-0 top-[55%]"
      >
        <img
          src="https://cdn-icons-png.flaticon.com/128/2504/2504947.png"
          alt="Twitter"
          className="w-8 h-8 transition-transform transform hover:scale-110"
        />
      </a>
      <a
        href="https://in.linkedin.com/in/masterrajpaldsapvtltd"
        target="_blank"
        className="social-icon bg-[#0077b5] p-1 fixed  right-0 top-[60%]"
      >
        <img
          src="https://cdn-icons-png.flaticon.com/128/2504/2504923.png"
          alt="LinkedIn"
          className="w-8 h-8 transition-transform transform hover:scale-110"
        />
      </a>
    </div>
  );
};

export default StickyNavbar;
