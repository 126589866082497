import React from "react";

const Nodataimg = () => {
  return (
    <div className="w-full flex items-center justify-center">
      <img
        className="h-[250px]"
        src="https://cdn.staropstech.com/clients/udharibazaar/assets/extra-images/nodata.webp"
        alt="nodata"
      />
    </div>
  );
};

export default Nodataimg;
