import React from "react";
import LoanCard from "../../Common/LoanCard";
import data from "../../Data/CardsData.json";
import About from "./About";
import WhyChooseUsCard from "../../Common/WhyChooseUsCard";
import CountsCard from "../../Common/CountsCard";
import PartnersIconCard from "./PartnersIconCard";
import Reviews from "./Reviews";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import image1 from "../../Assets/bannerImage/image1.png";
import image2 from "../../Assets/bannerImage/image2.png";
import image3 from "../../Assets/bannerImage/image3.png";
import image4 from "../../Assets/bannerImage/image4.png";
import { useNavigate } from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const banners = [
  {
    mainLine: "Power of Easy Loans",
    subLine: "Explore the features and enjoy!",
    buttonText: "Apply Now",
    buttonUrl: "/become-partner",
    imageUrl: image1,
  },
  {
    mainLine: "Discover Something Amazing",
    subLine: "An experience like never before.",
    buttonText: "Apply Now",
    buttonUrl: "/become-partner",
    imageUrl: image2,
  },
];
const bannersMobile = [
  {
    mainLine: "Power of Easy Loans",
    subLine: "Explore the features and enjoy!",
    buttonText: "Apply Now",
    buttonUrl: "/become-partner",
    imageUrl: image3,
  },
  {
    mainLine: "Discover Something Amazing",
    subLine: "An experience like never before.",
    buttonText: "Apply Now",
    buttonUrl: "/become-partner",
    imageUrl: image4,
  },
];

const Banner = () => {
  const navigate = useNavigate();

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <div className="hidden sm:block relative">
        {/* Carousel for desktop */}
        <Carousel
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          responsive={responsive}
          swipeable={false}
          draggable={false}
          arrows={false}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          containerClass="carousel-container"
        >
          {banners.map((banner, key) => (
            <div key={key} className="carousel-item">
              <img
                className="h-[75vh] w-full object-cover sm:object-fill"
                src={banner.imageUrl}
                alt={`banner ${key}`}
              />
              <div className="absolute top-[40%] left-[5%] text-start flex flex-col gap-2">
                <h1 className="text-[#f9f9f9] text-6xl font-bold text-wrap">
                  {banner.mainLine}
                </h1>
                <p className="text-[#f9f9f9] text-xl font-normal">
                  {banner.subLine}
                </p>
                <button
                  className=" rounded-xl bg-white text-theblack w-36 h-12 text-xl"
                  onClick={() => navigate(`${banner.buttonUrl}`)}
                >
                  {banner.buttonText}
                </button>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <div className="sm:hidden relative">
        {/* Carousel for mobile */}
        <Carousel
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          responsive={responsive}
          swipeable={false}
          draggable={false}
          arrows={false}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          containerClass="carousel-container"
        >
          {bannersMobile.map((banner, key) => (
            <div key={key} className="carousel-item">
              <img
                className="h-[75vh] w-full object-cover sm:object-fill"
                src={banner.imageUrl}
                alt={`banner ${key}`}
              />
              <div className="absolute top-[70%] left-[5%] text-start flex flex-col gap-2">
                <h1 className="text-[#f9f9f9] text-4xl font-bold text-wrap">
                  {banner.mainLine}
                </h1>
                <p className="text-[#f9f9f9] text-lg font-normal">
                  {banner.subLine}
                </p>
                <button
                  className="rounded-xl bg-white text-theblack w-40 h-10 text-xl"
                  onClick={() => navigate(`${banner.buttonUrl}`)}
                >
                  {banner.buttonText}
                </button>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
const HomePage = () => {
  return (
    <>
      <div className="w-full">
        <Banner />
      </div>
      <div className="container mx-auto mb-5 py-5">
        <h1 className="py-2 text-3xl text-theblack font-medium w-full text-center">
          Our Offerings
        </h1>
        <div className="mt-10 gap-5 grid grid-cols-1 md:grid-cols-3 place-items-center">
          {data.loancard.map((value, key) => {
            return <LoanCard data={value} key={key} />;
          })}
        </div>
      </div>
      <About />
      <div className="container mx-auto my-5 py-5">
        <h1 className="py-2 text-3xl text-theblack font-medium w-full text-center ">
          Why Choose Us
        </h1>
        <div className="mt-10  grid grid-cols-2 md:grid-cols-4 gap-10 place-items-center">
          {data.whyChooseus.map((value, key) => {
            return <WhyChooseUsCard data={value} key={key} />;
          })}
        </div>
      </div>
      <div className="bg-greywhite my-5 py-5">
        <div className="container mx-auto   ">
          <h1 className="py-2 text-3xl text-theblack font-medium w-full text-center ">
            Sucess Story Of Master Rajpal DSA
          </h1>
          <div className="mt-10 gap-5 flex flex-row items-center justify-between flex-wrap ">
            {data.countmrrajpal.map((value, key) => {
              return <CountsCard data={value} key={key} />;
            })}
          </div>
        </div>
      </div>
      <Reviews />
      <div className="bg-greywhite my-5 pt-5">
        <div className="container mx-auto">
          <h1 className="py-2 text-3xl text-theblack font-medium w-full text-center ">
            Our Lending Banks & NBFS
          </h1>
          <div className="mt-10 gap-5 flex flex-row items-center justify-center flex-wrap ">
            {data.partnerIcon.map((value, key) => {
              return <PartnersIconCard data={value} key={key} />;
            })}
          </div>
          <div className="conatiner mx-auto flex item-center justify-center">
            <h1 className="secondary-heading text-center mt-6">
              and many more...
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
