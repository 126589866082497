if (process.env.REACT_APP_ENV === "PROD") {
  var baseURL = "https://public-api.masterrajpaldsa.com/";
} else {
  var baseURL =
    "https://b45a-2405-201-403c-208f-1eb1-f3c1-df35-41ee.ngrok-free.app/";
}

const properties = {
  api: {
    baseURL: baseURL,
    partnerEnquiery: "api/v1/partner/register/",

    // Admin Urls
    adminLogin: "api/v1/admin/login/",
    adminDashboard: "api/v1/admin/dashboard/",
    adminStatusUpdate: "api/v1/admin/dashboard/${id}/",
    adminInfoUpdate: "api/v1/admin/adminupdateprofile/",
  },
};

export default properties;

// https://0627-2405-201-403c-208f-ef03-9a11-a8c9-691b.ngrok-free.app/
