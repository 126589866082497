import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import AmortizationTable from "./AmortizationTable"; // Assuming you have a separate component for the amortization table
Chart.register(ArcElement);

const InputSlider = ({
  label,
  name,
  min,
  max,
  step,
  value,
  onChange,
  minval,
  maxval,
}) => {
  const handleBlur = () => {
    const newValue = Math.min(Math.max(parseFloat(value), min), max);
    onChange({ target: { name, value: newValue } });
  };

  const handleNumberInputChange = (e) => {
    onChange(e);
  };

  return (
    <div>
      <div className="w-full flex flex-row justify-between">
        <label
          htmlFor="default-range"
          className="block mb-1 text-sm font-medium text-gray-900"
        >
          {label}
        </label>

        <input
          type="number"
          name={name}
          className="border-theblack border-opacity-50 border w-36 text-right px-1 rounded-md text-base focus:outline-none focus:ring-2 focus:ring-logo-orange appearance-none"
          value={value}
          min={min}
          max={max}
          onChange={handleNumberInputChange}
          onBlur={handleBlur}
        />
      </div>
      <input
        id="default-range"
        name={name}
        type="range"
        min={min}
        step={step}
        max={max}
        value={value}
        onChange={onChange}
        className="w-full h-2 bg-theblack rounded-lg cursor-pointer appearance-none [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-[20px] [&::-webkit-slider-thumb]:w-[20px] [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-theblack"
      />
      <div className="w-full flex flex-row justify-between">
        <p className="text-xs text-slate-700 font-semibold">{minval}</p>
        <p className="text-xs text-slate-700 font-semibold">{maxval}</p>
      </div>
    </div>
  );
};

const Calculator = () => {
  const [formInput, setFormInput] = useState({
    principal: 10000,
    rate: 1,
    time: 1,
  });

  const [emiData, setEmidata] = useState({
    totalAmount: 0,
    principleAmount: 0,
    emi: 0,
    totalInterest: 0,
  });

  const [amortizationData, setAmortizationData] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  const [pieData, setPieData] = useState({
    labels: ["EMI", "Total Amount", "Total Interest"],
    datasets: [
      {
        data: [emiData.principleAmount, emiData.totalInterest],
        backgroundColor: ["#3e3e3e", "#4c4c4c"],
        hoverOffset: 4,
      },
    ],
  });

  const calculateEMI = (principal, rate, tenureYears) => {
    const monthlyRate = rate / 12 / 100;
    const tenureMonths = tenureYears * 12;
    const emi =
      (principal * monthlyRate * Math.pow(1 + monthlyRate, tenureMonths)) /
      (Math.pow(1 + monthlyRate, tenureMonths) - 1);
    const totalAmount = emi * tenureMonths;
    const totalInterest = totalAmount - principal;

    return {
      totalAmount: totalAmount.toFixed(2),
      principleAmount: principal,
      emi: parseFloat(emi.toFixed(2)), // Fix here
      totalInterest: totalInterest.toFixed(2),
    };
  };

  useEffect(() => {
    const { totalAmount, principleAmount, emi, totalInterest } = calculateEMI(
      formInput.principal,
      formInput.rate,
      formInput.time
    );
    setEmidata({
      ...emiData,
      totalAmount,
      principleAmount,
      emi,
      totalInterest,
    });
    setPieData({
      datasets: [
        {
          data: [principleAmount, totalInterest],
          backgroundColor: ["#3e3e3e", "#c4c4c4"],
          hoverOffset: 4,
        },
      ],
    });

    // Calculate amortization schedule
    const monthlyRate = formInput.rate / 12 / 100;
    const tenureMonths = formInput.time * 12;
    let openingBalance = parseFloat(formInput.principal);
    let amortizationSchedule = [];

    for (let month = 1; month <= tenureMonths; month++) {
      const interestPayment = openingBalance * monthlyRate;
      const principalPayment = emi - interestPayment;
      const closingBalance = openingBalance - principalPayment;

      amortizationSchedule.push({
        month,
        openingBalance: openingBalance.toFixed(2),
        emi: emi.toFixed(2),
        principalRepaid: principalPayment.toFixed(2),
        interestPaid: interestPayment.toFixed(2),
        closingBalance: closingBalance.toFixed(2),
      });

      openingBalance = closingBalance;
    }

    setAmortizationData(amortizationSchedule);
  }, [formInput]);

  const HandleOnchange = (e) => {
    const { name, value } = e.target;

    let minVal, maxVal;

    switch (name) {
      case "principal":
        minVal = 0;
        maxVal = 50000000;
        break;
      case "rate":
        minVal = 1;
        maxVal = 35;
        break;
      case "time":
        minVal = 1;
        maxVal = 35;
        break;
      default:
        minVal = 0;
        maxVal = 0;
    }

    const sanitizedValue = isNaN(parseFloat(value))
      ? formInput[name]
      : parseFloat(value);

    if (name === "principal" && sanitizedValue < minVal) {
      setErrorMessage("Please enter an amount greater than 50k");
      return;
    } else {
      setErrorMessage("");
    }

    const clampedValue = Math.min(Math.max(sanitizedValue, minVal), maxVal);

    setFormInput((prevFormInput) => ({
      ...prevFormInput,
      [name]: clampedValue,
    }));
  };

  return (
    <>
      <div className="container mx-auto box-shadow">
        <h2 className="text-3xl font-semibold lg:main-heading mb-6 text-center">
          Calculate your Needs
        </h2>

        <div className="flex flex-col lg:flex-row gap-4 ">
          <div className="flex flex-col gap-4 p-5 lg:w-3/4 lg:ml-5">
            <InputSlider
              name="principal"
              label="Loan Amount"
              onChange={HandleOnchange}
              value={formInput.principal}
              step={1000}
              min={10000}
              max={50000000}
              minval="10 k"
              maxval="5 Cr."
            />
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}

            <InputSlider
              label="Interest Rate(% P.A.)"
              name="rate"
              onChange={HandleOnchange}
              value={formInput.rate}
              step={0.1}
              min={1}
              max={35}
              minval="1 %"
              maxval="35 %"
            />

            <InputSlider
              name="time"
              label="Tenure (year)"
              onChange={HandleOnchange}
              value={formInput.time}
              step={0.5}
              min={1}
              max={35}
              minval="1 year"
              maxval="35 year"
            />
          </div>
          <div>
            <Doughnut data={pieData} options={{ responsive: true }} />
          </div>
        </div>

        <div className="flex lg:gap-4 justify-between lg:px-5 pb-5 mt-10">
          <p className="font-bold lg:secondary-heading text-center">
            Principal Amount
            <span className="block text-xl font-extralight mt-3 ">
              {emiData.principleAmount}
            </span>
          </p>
          <p className="font-bold lg:secondary-heading text-center">
            EMI Per Month
            <span className="block text-xl font-extralight mt-3 ">
              {emiData.emi}
            </span>
          </p>
          <p className="font-bold lg:secondary-heading text-center">
            Total Amount
            <span className="block text-xl font-extralight mt-3">
              {emiData.totalAmount}
            </span>
          </p>
          <p className="font-bold lg:secondary-heading text-center">
            Total Interest
            <span className="block text-xl font-extralight mt-3">
              {emiData.totalInterest}
            </span>
          </p>
        </div>
      </div>
      <AmortizationTable data={amortizationData} />
    </>
  );
};

export default Calculator;
