import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Reviews = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div className="py-5 my-5" id="reviews">
        <div className="container mx-auto my-5 py-5">
          <h1 className="main-heading mb-6 text-center ">Customers Review</h1>
          <Carousel
            className="z-0 mt-10"
            infinite={true}
            responsive={responsive}
            arrows={false}
            removeArrowOnDeviceType={["tablet", "mobile"]}
          >
            <div className=" border-logo w-full shadow-sm box-shadow  md:w-2/3 mx-auto flex flex-col md:flex-row  border rounded-md p-2 gap-2 md:gap-6 md:p-6  ">
              <img
                className="h-44 rounded-md object-cover"
                src="https://gitlab.com/uploads/-/system/user/avatar/19683607/avatar.png"
                alt="profile"
              />
              <div className="heading text-justify">
                <p>
                  MasterRajPal DSA delivered an exceptional loan experience.
                  From application to approval, their team ensured a seamless
                  process. Transparent terms, competitive rates, and responsive
                  customer service make them my top choice.
                </p>
                <div className="mt-2">
                  <p className="secondary-heading">Divyanshu verma</p>
                  <p></p>
                </div>
              </div>
            </div>
            <div className=" border-logo w-full shadow-lg shadow-blue-200  md:w-2/3 mx-auto flex flex-col md:flex-row  border rounded-md p-6 gap-2 md:gap-6 ">
              <img
                className="h-44 rounded-md"
                src="https://gitlab.com/uploads/-/system/user/avatar/19683607/avatar.png"
                alt="profile"
              />
              <div className="font-light text-justify">
                <p>
                  MasterRajPal DSA stands out with their customer-centric
                  approach. Professionalism, clear communication, and ethical
                  practices define their service. Highly recommended for those
                  seeking reliable and hassle-free loan solutions.
                </p>
                <div className="mt-2">
                  <p>Dewang Sharma</p>
                  <p>team Udharibazar</p>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default Reviews;
