import React, { useContext } from "react";

const LoaderContext = React.createContext();

export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const showLoader = () => setIsLoading(true);
  const hideLoader = () => setIsLoading(false);

  return (
    <LoaderContext.Provider value={{ showLoader, hideLoader }}>
      {children}
      {isLoading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-white bg-opacity-70 backdrop-filter backdrop-blur-lg z-50 flex justify-center items-center">
          <div className="loader relative">
            <div className="w-12 h-12  bg-theblack rounded-full absolute top-0 left-0 animate-ping"></div>
            <div
              className="w-8 h-8  bg-theblack rounded-full absolute top-2 left-2 animate-pulse"
              style={{ animationDelay: "-1s" }}
            ></div>
          </div>
        </div>
      )}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error("useLoader must be used within a LoaderProvider");
  }
  return context;
};
