import React from "react";

const LoanCard = ({ data }) => {
  return (
    <div className="border rounded-md shadow-md p-3 hover:translate-y-[-5px] transition-all duration-500 ease-in-out">
      <div className="flex flex-row items-center justify-between">
        <h1 className="secondary-heading">{data.heading}</h1>
        <img className="h-8" src={data.imageUrl} alt="loan icon" />
      </div>
      <p className="heading">{data.description}</p>
      <button className="text-theblack group flex flex-row hover:text-gray-500 hover:underline underline-offset-4">
        Read More
        <svg
          className="ml-1 scale-75 group-hover:translate-x-2 transition-all duration-500 ease-in-out"
          fill="#00000"
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
        >
          <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
        </svg>
      </button>
    </div>
  );
};

export default LoanCard;
