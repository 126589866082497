import React from "react";

const FeeAndCharges = ({ feeAndChargesData }) => {
  if (!feeAndChargesData) {
    return null;
  }

  const { title, details } = feeAndChargesData;

  return (
    <div className="py-5 my-3" id="feesCharges">
      <div className="container mx-auto mt-10">
        <h2 className="text-3xl font-semibold lg:main-heading mb-6 text-center">
          {title}
        </h2>
        <div className="container bg-white box-shadow relative p-4 mt-4 rounded-md">
          <div className="flex justify-between">
            <h3 className="secondary-heading">Particulars</h3>
            <h3 className="secondary-heading">Charges</h3>
          </div>
          <div className="flex justify-between mt-2">
            <ul>
              {details.map((item, index) => (
                <li key={index} className="flex justify-between mt-2 heading">
                  {item.particular}
                </li>
              ))}
            </ul>
            <ul>
              {details.map((item, index) => (
                <li
                  key={index}
                  className="flex justify-between mt-2 heading text-end"
                >
                  {item.charges}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeAndCharges;
