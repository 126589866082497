import React from "react";

const Eligibility = ({ eligibilityData }) => {
  if (!eligibilityData) {
    return null;
  }

  const { title, criteria, documents } = eligibilityData;

  return (
    <div className="py-5 my-5" id="eligibility">
      <div className="container mx-auto mt-20 lg:mt-10">
        <h2 className="text-3xl font-semibold lg:main-heading mb-6 text-center">
          Eligibility & Documents
        </h2>
        <div className="flex flex-col md:flex-row items-center justify-between gap-3">
          {/* Box 1 */}
          <div className="w-full sm:w-1/2 p-5 mb-5 sm:mb-0 bg-white box-shadow rounded-md relative">
            {/* <div className="top-0 left-0 w-full h-full">
              <img src="" alt="Figma Shape" className="w-full h-full" />
            </div> */}
            <h3 className="secondary-heading mb-2 text-center">
              Documents Needed for {title}
            </h3>
            <div className="w-10 h-1 bg-white mx-auto mb-4"></div>
            <ul className="list-disc pl-6 mt-4 heading">
              {criteria.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
          {/* Box 2 */}
          <div className="w-full sm:w-1/2 p-5 mb-5 sm:mb-0 bg-white box-shadow rounded-md relative">
            {/* <div className="top-0 left-0 w-full h-full">
              <img src="" alt="Figma Shape" className="w-full h-full" />
            </div> */}
            <h3 className="secondary-heading mb-2 text-center">
              Documents Needed for Personal Loan{" "}
            </h3>
            <div className="w-10 h-1 bg-white mx-auto mb-4"></div>
            <ul className="list-disc pl-6 mt-4 heading">
              {documents.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eligibility;
