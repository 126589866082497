import React from "react";

const VideoGallery = () => {
  return (
    <>
      <div className="py-5 my-5" id="video-gallery">
        <div className="container mx-auto">
          <h2 className="text-3xl font-semibold text-center">Video Gallery</h2>
          <p className="heading text-center mb-6">
            MASTER RAJPAL DSA PVT LTD is a corporate DSA of bank and Nbfc’s. It
            controlled By CA Nitin Rajpal. The Organisation main tag lnes are
            learn more earn more and don’t show off, because 90% money belongs
            to Broker.
          </p>
        </div>
      </div>
      <div className=" container mx-auto">
        <div className="flex flex-col md:flex-row items-center justify-between gap-3 bg-white box-shadow pb-3">
          {/* Video Card 1 */}
          <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
            <div className="bg-white rounded-md overflow-hidden shadow-md">
              <iframe
                className="w-full aspect-video"
                src="https://www.youtube.com/embed/KQ_djD6gt0Y"
                title="Video 1"
                allowFullScreen
              ></iframe>
              <div className="p-4">
                <button
                  className="bg-theblack text-white px-4 py-2 rounded-md block mx-auto"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/@masterrajpaldsapvtltd"
                    )
                  }
                >
                  View on YouTube
                </button>
              </div>
            </div>
          </div>

          {/* Video Card 2 */}
          <div className="w-full sm:w-1/2 lg:w-1/3 p-4 ">
            <div className="bg-white rounded-md overflow-hidden shadow-md">
              <iframe
                className="w-full aspect-video"
                src="https://www.youtube.com/embed/DMHW_6gLvAk"
                title="Video 1"
                allowFullScreen
              ></iframe>
              <div className="p-4">
                <button
                  className="bg-theblack text-white px-4 py-2 rounded-md block mx-auto"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/@masterrajpaldsapvtltd"
                    )
                  }
                >
                  View on YouTube
                </button>
              </div>
            </div>
          </div>

          {/* Video Card 3 */}
          <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
            <div className="bg-white rounded-md overflow-hidden shadow-md">
              <iframe
                className="w-full aspect-video"
                src="https://www.youtube.com/embed/2etnpinKpZ8"
                title="Video 1"
                allowFullScreen
              ></iframe>
              <div className="p-4">
                <button
                  className="bg-theblack text-white px-4 py-2 rounded-md block mx-auto"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/@masterrajpaldsapvtltd"
                    )
                  }
                >
                  View on YouTube
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-between gap-3 bg-white box-shadow pb-3">
          {/* Video Card 1 */}
          <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
            <div className="bg-white rounded-md overflow-hidden shadow-md">
              <iframe
                className="w-full aspect-video"
                src="https://www.youtube.com/embed/3xNUStp1ZjQ"
                title="Video 1"
                allowFullScreen
              ></iframe>
              <div className="p-4">
                <button
                  className="bg-theblack text-white px-4 py-2 rounded-md block mx-auto"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/@masterrajpaldsapvtltd"
                    )
                  }
                >
                  View on YouTube
                </button>
              </div>
            </div>
          </div>

          {/* Video Card 2 */}
          <div className="w-full sm:w-1/2 lg:w-1/3 p-4 ">
            <div className="bg-white rounded-md overflow-hidden shadow-md">
              <iframe
                className="w-full aspect-video"
                src="https://www.youtube.com/embed/HWcHxXW5uVk"
                title="Video 1"
                allowFullScreen
              ></iframe>
              <div className="p-4">
                <button
                  className="bg-theblack text-white px-4 py-2 rounded-md block mx-auto"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/@masterrajpaldsapvtltd"
                    )
                  }
                >
                  View on YouTube
                </button>
              </div>
            </div>
          </div>

          {/* Video Card 3 */}
          <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
            <div className="bg-white rounded-md overflow-hidden shadow-md">
              <iframe
                className="w-full aspect-video"
                src="https://www.youtube.com/embed/VVF-oDJlxxA"
                title="Video 1"
                allowFullScreen
              ></iframe>
              <div className="p-4">
                <button
                  className="bg-theblack text-white px-4 py-2 rounded-md block mx-auto"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/@masterrajpaldsapvtltd"
                    )
                  }
                >
                  View on YouTube
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-between gap-3 bg-white box-shadow pb-3">
          <button
            className="bg-theblack text-white px-4 py-2 rounded-md block mx-auto"
            onClick={() =>
              window.open(
                "https://www.youtube.com/@masterrajpaldsapvtltd/videos"
              )
            }
          >
            Our Youtube Channel
          </button>
        </div>
      </div>
    </>
  );
};

export default VideoGallery;
