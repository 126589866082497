import React, { useEffect, useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import Calculator from "../../Common/Calculator";
import Eligibility from "./Eligibility";
import Features from "./Features";
import QuickLauncher from "./QuickLauncher";
import FeeAndCharges from "./FeeAndCharges";
import FAQ from "./Faq";
import Reviews from "../Home/Reviews";
import { useLocation, useNavigate } from "react-router-dom";

const ServicesPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const loanType = pathname.split("/")[1];

  const [featuresData, setFeaturesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedMenuItem, setSelectedMenuItem] = useState("overview");

  const handleInvalidRoute = () => {
    navigate("/personal-loan");
  };

  const fetchData = async () => {
    try {
      const data = await import(`../../Data/PagesData.json`);
      return data.default[loanType] || null;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchDataAndSetState = async () => {
      const data = await fetchData();
      if (!data) {
        handleInvalidRoute();
        return;
      }
      setFeaturesData(data);
      setLoading(false);
    };

    fetchDataAndSetState();
  }, [fetchData]);

  if (loading) {
    return <p>Loading...</p>;
  }

  const menuItems = [
    "overview",
    "features",
    "eligibility",
    "feesCharges",
    "FAQ",
    "reviews",
  ];

  return (
    <>
      <div className="container mx-auto">
        <Calculator calculatorData={featuresData} />
        <ul className=" hidden md:block cursor-pointer items-center gap-4 lg:flex lg:flex-row lg:space-x-4 w-full justify-between mt-10 sticky top-16 bg-white z-50">
          {menuItems.map((menuItem) => (
            <div
              key={menuItem}
              className={`px-5 border-l-the-black border-l-2 ${
                selectedMenuItem === menuItem ? "border-[#3e3e3e]" : ""
              }`}
            >
              <li>
                <Link
                  className="secondary-heading"
                  to={menuItem}
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={() => setSelectedMenuItem(menuItem)}
                  style={{
                    color:
                      selectedMenuItem === menuItem ? "#3e3e3e" : "#c4c4c4",
                  }}
                >
                  {menuItem.charAt(0).toUpperCase() + menuItem.slice(1)}
                </Link>
              </li>
            </div>
          ))}
        </ul>
        <Features featuresData={featuresData} />
        <QuickLauncher />
        <Eligibility eligibilityData={featuresData.eligibilityData} />
        <FeeAndCharges feeAndChargesData={featuresData.feeAndChargesData} />
        {/* <VideoGallery /> */}
        <FAQ />
        <QuickLauncher />
        <Reviews />
      </div>
    </>
  );
};

export default ServicesPage;
