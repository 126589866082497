import React from "react";

const CountsCard = ({ data }) => {
  return (
    <div className="border w-52 h-60 rounded-md shadow-md p-3 flex flex-col items-center justify-between gap-1">
      <img
        className="h-20 aspect-square mt-3"
        src={data.imageUrl}
        alt="loan icon"
      />
      <h1 className="text-theblack text-xl font-medium">{data.heading}</h1>
      <p className="text-theblack  mb-4 text-4xl  pt-2 font-bold text-justify ">
        {data.amount}
        <span>{data.unit}</span>
      </p>
    </div>
  );
};

export default CountsCard;
