import React from "react";
import logo from "../../Assets/logo_white.png";
import instagram from "../../Assets/Icons/instagram.png";
import facebook from "../../Assets/Icons/facebook.png";
import youtube from "../../Assets/Icons/youtube.png";
import linkedin from "../../Assets/Icons/linkedin.png";
import twitter from "../../Assets/Icons/twitter.png";

const Footer = () => {
  return (
    <div className="bg-theblack pt-10 w-full p-2">
      <div className="container  md:p-0  mx-auto text-white  font-normal grid grid-cols-1 sm:grid-cols-2  md:grid-cols-4 gap-3">
        <div className="flex flex-col gap-2 py-4">
          <img
            src={logo}
            alt=" logo "
            width="80"
            height="80"
            className="mb-5"
          />
          <p className="text-xl font-semibold mb-2 mt-14">Contact Us</p>
          <p className="sub-heading">Email: info@masterrajpaldsa.com</p>
          <p className="sub-heading mb-2">Contact Number: +91 9310069549</p>
          <hr />
          <div className="flex  flex-row justify-between gap-4 items-center mt-2">
            <a
              href="https://www.instagram.com/masterrajpaldsapvtltd/"
              target="_blank"
              className="h-full w-full"
            >
              <img className="h-10 aspect-square" src={instagram} alt="" />
            </a>
            <a
              href="https://www.facebook.com/MASTERRAJPALDSAPVTLTD"
              target="_blank"
              className="h-full w-full"
            >
              <img className="h-10 aspect-square" src={facebook} alt="" />
            </a>
            <a
              href="https://www.youtube.com/@masterrajpaldsapvtltd"
              target="_blank"
              className="h-full w-full"
            >
              <img className="h-10 aspect-square" src={youtube} alt="" />
            </a>
            <a
              href="https://twitter.com/masterrajpaldsa"
              className="h-full w-full"
              target="_blank"
            >
              <img className="h-10 aspect-square" src={twitter} alt="" />
            </a>
            <a
              href="https://www.linkedin.com/in/masterrajpaldsapvtltd/"
              target="_blank"
              className="h-full w-full"
            >
              <img className="h-10 aspect-square" src={linkedin} alt="" />
            </a>
          </div>
        </div>
        <div>
          <h1 className="text-xl font-semibold mb-2">Loans</h1>
          <ul>
            <li>
              <a className="sub-heading" href="/">
                Personal Loan
              </a>
            </li>
            <li>
              <a className="sub-heading" href="/">
                Home Loan
              </a>
            </li>
            <li>
              <a className="sub-heading" href="/">
                Business Loan
              </a>
            </li>
            <li>
              <a className="sub-heading" href="/">
                Auto Loan
              </a>
            </li>
            <li>
              <a className="sub-heading" href="/">
                Gold Loan
              </a>
            </li>
            <li>
              <a className="sub-heading" href="/">
                Loan against Property
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h1 className="text-xl font-semibold mb-2">About</h1>
          <ul>
            <li>
              <a className="sub-heading" href="/">
                About Us
              </a>
            </li>
            <li>
              <a className="sub-heading" href="/">
                FAQ
              </a>
            </li>
            <li>
              <a className="sub-heading" href="/">
                Blogs
              </a>
            </li>
            <li>
              <a className="sub-heading" href="/">
                Privacy Policy
              </a>
            </li>
            <li>
              <a className="sub-heading" href="/">
                Feedback
              </a>
            </li>
            <li>
              <a className="sub-heading" href="/">
                Terms of Use
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h1 className="text-xl font-semibold mb-2">Address</h1>
          <ul>
            <li>
              <p className="sub-heading">
                U-67 Upper Second Floor UPADHYAY BLOCK, Shakarpur, <br />
                Laxmi Nagar, East Delhi,
                <br /> Delhi, 110092
              </p>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div className="text-center text-xs text-white p-1">
        <p>Copyright © 2024 MasterRajpal DSA. All rights reserved.</p>
        <a href="https://staropstech.com/" target="_blank">
          Managed by StarOps Technologies Pvt Ltd
        </a>
      </div>
    </div>
  );
};

export default Footer;
