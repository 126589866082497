import React from "react";

const AmortizationTable = ({ data }) => {
  console.log("this is data", data);

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <div className="py-5 my-5">
      <div className="container bg-greywhite box-shadow mx-auto mt-10">
        <h2 className="text-3xl font-semibold lg:main-heading mb-6 text-center">
          Amortization Schedule
        </h2>
        <div className="table-container overflow-x-auto">
          <table className="w-full bg-[#f9f9f9] rounded-lg p-4 border">
            <thead>
              <tr>
                <th className="secondary-heading  text-theblack">Month</th>
                <th className="secondary-heading  text-theblack">
                  Opening Balance
                </th>
                <th className="secondary-heading  text-theblack">EMI</th>
                <th className="secondary-heading  text-theblack">
                  Principal Repaid
                </th>
                <th className="secondary-heading  text-theblack">
                  Interest Paid
                </th>
                <th className="secondary-heading  text-theblack">
                  Closing Balance
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td className="text-center border-b border-gray-300 py-2 text-sm md:text-base">
                    {row.month}
                  </td>
                  <td className="text-center border-b border-gray-300 py-2 text-sm md:text-base">
                    {row.openingBalance}
                  </td>
                  <td className="text-center border-b border-gray-300 py-2 text-sm md:text-base">
                    {row.emi}
                  </td>
                  <td className="text-center border-b border-gray-300 py-2 text-sm md:text-base">
                    {row.principalRepaid}
                  </td>
                  <td className="text-center border-b border-gray-300 py-2 text-sm md:text-base">
                    {row.interestPaid}
                  </td>
                  <td className="text-center border-b border-gray-300 py-2 text-sm md:text-base">
                    {row.closingBalance}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AmortizationTable;
