import React, { useEffect, useState } from "react";
import ListTable from "../../Common/ListTable";
import Nodataimg from "./NoDataImg";
import "react-toastify/dist/ReactToastify.css";
import { GetTimeAgo, StatusTag } from "../../Helper/HelperFunctions";
import properties from "../../properties/Properties";
import GenerateURL, { GenerateSearchURL } from "../../utils/ApiUrlProvider";
import { GetData, PutData } from "../../utils/ApiInvoker";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import PartnerDetailsModal from "./PartnerDeatilsModal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import NewSearchBar from "../../Common/NewSearchBar";
import SearchBar from "../../Common/SearchBar";
import ModalWindow from "../../Helper/ModalWindow";

const PartnerList = () => {
  const [tableData, setTableData] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [allData, SetAllData] = useState(null);

  const convertDataToTableFormat = (data) => {
    const tableHeaders = [
      "Name",
      "Phone Number",
      "E-mail",
      "Category",
      "Acknowledged",
      "Time",
    ];

    const tableContent = data.map((user) => {
      return {
        Name: user.name || "--",
        Category: user.category || "--",
        "Phone Number": user.mobile_no || "--",
        "E-mail": user.email || "--",
        Acknowledged: StatusTag(user.ack),
        Time: GetTimeAgo(user.created_at),
        Message: (
          <span className="relative group">
            {user.message ? (
              <span className="group-hover:underline cursor-pointer text-[#7D8398] font-semibold -z-50">
                {`${user.message.split(" ").slice(0, 3).join(" ")} ...`}
              </span>
            ) : (
              <span className="text-[#7D8398] -z-50">{`--`}</span>
            )}
            {user.message && (
              <div className="absolute hidden left-5 bottom-3 bg-[#f9f9f9] border border-theblack/20 p-2 box-shadow group-hover:block w-52 z-50">
                <div className="text-the-black font-normal text-sm">
                  {user.message}
                </div>
              </div>
            )}
          </span>
        ),
        CSVMessage: user.message,
        CSVAcknowledged: user.ack,
        City: user.city || "--",
        State: user.state || "--",
        Pincode: user.pincode || "--",
        "Acknowledged By": user.ack_by ? user.ack_by.first_name : "--",
        Description: user.description || "--",
        id: user.id,
      };
    });

    return {
      tableHeaders,
      tableContent,
    };
  };

  const HandleSearchSucess = (res) => {
    if (res.results.length === 0) {
      toast.error("No Data found");
      GetUserList();
    } else {
      HandleSuccess(res);
    }
  };

  const searchParameters = [
    { name: "Name", parm: "name" },
    { name: "E-mail", parm: "email" },
    { name: "Phone Number", parm: "mobile_no" },
    { name: "Category", parm: "category" },
    { name: "Acknowledged", parm: "ack" },
    { name: "Acknoledged By", parm: "ack_by" },
  ];

  const SearchClick = (e) => {
    console.log(e);
    const url = GenerateSearchURL(e, properties.api.adminDashboard);
    GetData(url, HandleSearchSucess, HandleError, false, false);
  };

  const EmptyCall = () => {
    GetUserList();
  };

  const HandleSuccess = (response) => {
    setTotalCount(response.count);
    const data = convertDataToTableFormat(response.results);
    setTableData(data);
    setPagination(response);
  };

  const HandleError = (response) => {
    console.log(response);
  };

  const GetNextFilledServices = (nexturl) => {
    const url = GenerateURL({}, nexturl);
    GetData(url, HandleSuccess, HandleError, false, false);
  };

  const GetPrevFilledServices = (prevurl) => {
    const url = GenerateURL({}, prevurl);
    GetData(url, HandleSuccess, HandleError, false, false);
  };

  const GetUserList = () => {
    const url = GenerateURL({}, properties.api.adminDashboard);
    GetData(url, HandleSuccess, HandleError, false, false);
  };

  useEffect(() => {
    GetUserList();
    GettingAllData();
  }, []);

  // Handle All Data

  const handleAllDataFailure = (error) => {
    console.log(error);
  };

  const GettingAllData = () => {
    const url = GenerateSearchURL({ all: true }, properties.api.adminDashboard);
    GetData(url, HandleAllData, handleAllDataFailure, false, false);
  };

  const HandleAllData = (res) => {
    SetAllData(res.results);
    // console.log("This is All Data", allData);
  };

  const exportCSV = () => {
    if (allData) {
      const csvData = allData.map((item, index) => ({
        "#": index + 1,
        Name: item.name,
        "Phone Number": item.mobile_no,
        "E-mail": item.email,
        Category: item.category,
        Message: item.message,
        City: item.city,
        State: item.state,
        Pincode: item.pincode,
        Acknowledged: item.ack,
        "Acknowledged By": item.ack_by,
        Time: GetTimeAgo(item.updated_at),
      }));
      return csvData;
    }
    return [];
  };

  const exportPDF = () => {
    if (allData) {
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      const pdfData = allData.map((item, index) => [
        index + 1,
        item.name,
        item.mobile_no || "--",
        item.email || "--",
        item.category || "--",
        item.message || "--",
        item.city || "--",
        item.state || "--",
        item.pincode || "--",
        item.ack || "--",
        item.ack_by || "--",
        GetTimeAgo(item.updated_at),
      ]);

      pdf.autoTable({
        head: [
          [
            "#",
            "Name",
            "Mob No.",
            "E-mail",
            "Category",
            "Message",
            "City",
            "State",
            "Pincode",
            "ACK",
            "Ack By",
            "Day",
          ],
        ],
        headStyles: {
          lineWidth: 1,
          fillColor: [62, 62, 62],
          textColor: [255, 255, 255],
        },
        body: [...pdfData],

        columnStyles: {
          0: { columnWidth: 10, fontStyle: "bold", halign: "left" },
          1: {
            columnWidth: 25,
            fontStyle: "bold",
            halign: "left",
          },
          2: {
            columnWidth: 30,
            fontStyle: "bold",
            halign: "left",
          },
          3: {
            columnWidth: 45,
            fontStyle: "bold",
            halign: "left",
          },
          4: {
            columnWidth: 25,
            fontStyle: "bold",
            halign: "left",
          },
          5: {
            columnWidth: 45,
            fontStyle: "bold",
            halign: "left",
          },
          6: {
            columnWidth: 20,
            fontStyle: "bold",
            halign: "left",
          },
          7: {
            columnWidth: 20,
            fontStyle: "bold",
            halign: "left",
          },
          8: {
            columnWidth: 15,
            fontStyle: "bold",
            halign: "left",
          },
          9: {
            columnWidth: 15,
            fontStyle: "bold",
            halign: "left",
          },
          10: {
            columnWidth: 15,
            fontStyle: "bold",
            halign: "left",
          },
          11: {
            columnWidth: 10,
            fontStyle: "bold",
            halign: "left",
          },
        },
      });

      pdf.save("partner_data.pdf");
    }
  };

  const clickOn = (e) => {
    setModalData(e);
    setIsModalOpen(true);
  };

  const clickableOn = {
    on: ["Name", "Phone Number", "E-mail", "Category", "Acknowledged", "Time"],
    funct: {
      Name: clickOn,
      "Phone Number": clickOn,
      "E-mail": clickOn,
      Category: clickOn,
      Acknowledged: clickOn,
    },
  };

  //For Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    description: "",
  });
  const [errors, setErrors] = useState({});

  const toggleEditModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSuccess = (response) => {
    toast.success("Acknowledged Sucessfully");
    setIsModalOpen(false);
  };

  const handleFailure = (error) => {
    toast.error("Opps! Something went wrong");
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = GenerateURL(
      { id: modalData.id },
      properties.api.adminStatusUpdate
    );
    PutData(url, formData, handleSuccess, handleFailure, false);
  };

  return (
    <div className="w-11/12">
      {tableData && tableData.tableContent.length === 0 ? (
        <div className="flex flex-col gap-2">
          <Nodataimg />
          <h1 className="text-center text-xl text-logo-blue font-extrabold">
            No Users Found
          </h1>
        </div>
      ) : (
        tableData && (
          <>
            <div className="w-[100vw] flex flex-col">
              <div className="mt-20 container mx-auto">
                <a href="/admin/login">
                  Admin <span className="">/ </span>
                </a>
                <a className="font-semibold" href="">
                  Admin Dashboard
                </a>
              </div>
              <div className="container flex flex-col gap-5 lg:flex-row lg:items-center lg:justify-between mt-6 mx-auto ">
                <div className="flex flex-col ">
                  <h1 className="text-[28px] font-medium text-[#3e3e3e]">
                    Partner Table
                  </h1>
                  <p className="text-[16px] font-normal text-[#7d8398]">
                    This is an Overview of the meta information of partner's
                    related enquierys. Current enquiery count is {totalCount}.
                  </p>
                </div>
                <div className="flex flex-row gap-2">
                  {allData && (
                    <CSVLink
                      data={exportCSV()}
                      filename={"partner_data.csv"}
                      className="px-3 py-2 rounded-xl border border-theblack/40  text-theblack flex justify-between hover:bg-theblack hover:text-white"
                      type="submit"
                    >
                      Export as CSV
                    </CSVLink>
                  )}
                  <button
                    className="px-3 py-2 rounded-xl border-theblack/40 border  text-theblack flex justify-between hover:bg-theblack hover:text-white"
                    onClick={exportPDF}
                  >
                    Export as PDF
                  </button>
                </div>
              </div>
              <NewSearchBar
                searchParameters={searchParameters}
                SearchClick={SearchClick}
                EmptyCall={EmptyCall}
              />
              <ListTable
                heading="Total Partner"
                tableData={tableData}
                pagination={Boolean(pagination.next || pagination.previous)}
                next={pagination.next}
                prev={pagination.previous}
                onNextClick={() => GetNextFilledServices(pagination.next)}
                onPrevClick={() => GetPrevFilledServices(pagination.previous)}
                actions={{ view: false, edit: true, delete: false }}
                clickabaleOn={clickableOn}
              />
            </div>
          </>
        )
      )}
      {isModalOpen && (
        <ModalWindow onClose={toggleEditModal} title="Acknowldge Your Partner">
          <div className="w-full  bg-white p-6 rounded-md">
            <div className="flex flex-col mt-4 px-4">
              <h3 className="secondary-heading border-b-2">
                Personal Information
              </h3>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
                <div className="flex flex-col">
                  <p className="heading">Name</p>
                  <p className="text-[16px] text-[#738398]">{modalData.Name}</p>
                </div>
                <div className="flex flex-col">
                  <p className="heading">Email</p>
                  <p className="text-[16px] text-[#738398]">
                    {modalData["E-mail"]}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="heading">Mobile Number</p>
                  <p className="text-[16px] text-[#738398]">
                    {modalData["Phone Number"]}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="heading">City</p>
                  <p className="text-[16px] text-[#738398]">{modalData.City}</p>
                </div>
                <div className="flex flex-col">
                  <p className="heading">State</p>
                  <p className="text-[16px] text-[#738398]">
                    {modalData.State}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="heading">Pincode</p>
                  <p className="text-[16px] text-[#738398]">
                    {modalData.Pincode}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col px-4 mt-6">
              <h3 className="secondary-heading border-b-2">Query</h3>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
                <div className="flex flex-col">
                  <p className="heading">Category</p>
                  <p className="text-[16px] text-[#738398]">
                    {modalData.Category}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="heading">Message</p>
                  <p className="text-[16px] text-[#738398]">
                    {modalData.CSVMessage}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="heading">Time</p>
                  <p className="text-[16px] text-[#738398]">{modalData.Time}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col px-4 mt-6">
              <h3 className="secondary-heading border-b-2">Acknowledgement</h3>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
                <div className="flex flex-col">
                  <p className="heading">Acknowledged</p>
                  <p className="text-[16px] text-[#738398]">
                    {StatusTag(modalData.CSVAcknowledged)}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="heading">Acknowledged By</p>
                  <p className="text-[16px] text-[#738398]">
                    {modalData["Acknowledged By"]}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="heading">Description</p>
                  <p className="text-[16px] text-[#738398]">
                    {modalData.Description}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full gap-4 justify-center items-center mt-10">
              <div className="flex  flex-col lg:flex-row gap-2 item-center justify-center text-center w-full">
                <p className="heading">Acknowledge Message</p>
                <input
                  type="text"
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Enter Your Description"
                  className="shadow-md h-8 w-full  lg:w-[25%] px-2 rounded-lg text-theblack/80"
                  required
                />
              </div>
              <button
                className="px-3 py-2 rounded-xl bg-theblack text-white w-20 flex justify-between"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </ModalWindow>
      )}
    </div>
  );
};

export default PartnerList;
