import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./Routes";
import { LoaderProvider } from "./Common/GlobalLoader";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LoaderProvider>
      <App />
    </LoaderProvider>
  </React.StrictMode>
);
