import React, { useState, useRef, useEffect } from "react";

const NewSearchBar = ({ searchParameters, SearchClick, EmptyCall }) => {
  const [selectedParameter, setSelectedParameter] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [isDateFilter, setDateFilter] = useState(false);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const dropdownRef = useRef(null);
  const searchContainerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        dropdownRef.current.style.display = "none";
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchContainerRef]);

  const handleParameterSelect = (parameter) => {
    setSelectedParameter(parameter);
    setSearchInput(`${parameter}=`);
  };

  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleDateFilterToggle = () => {
    setDateFilter(!isDateFilter);
  };

  const handleSearchClick = () => {
    if (isDateFilter) {
      const startDate = startDateRef.current.value;
      const endDate = endDateRef.current.value;

      if (startDate && endDate) {
        SearchClick({ startDate, endDate });
      }
    } else {
      const data = selectedParameter
        ? { [selectedParameter]: searchInput.split("=")[1] }
        : { search: searchInput };
      SearchClick(data);
    }
    dropdownRef.current.style.display = "none";
  };

  const handleSearchBarClick = () => {
    if (searchParameters && dropdownRef.current) {
      dropdownRef.current.style.display = "block";
    }
  };

  return (
    <div className="relative container mt-6 mx-auto" ref={searchContainerRef}>
      <form className="flex flex-row gap-2">
        <input
          type="text"
          value={searchInput}
          onChange={handleInputChange}
          onClick={handleSearchBarClick}
          className="border rounded-lg w-full bg-white h-10 px-5 pr-10 text-base text-theblack font-medium focus:outline-none"
          placeholder="Search in data easily..."
        />
        <div
          ref={dropdownRef}
          className="absolute top-full mt-1 z-50 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 hidden"
        >
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {searchParameters &&
              searchParameters.map((value, key) => (
                <div
                  key={key}
                  onClick={() => {
                    handleParameterSelect(value.parm);
                    dropdownRef.current.style.display = "none";
                  }}
                  className={`block px-4 py-2 text-sm text-gray-700 hover:bg-theblack hover:text-white cursor-pointer ${
                    selectedParameter === value.parm
                      ? "bg-theblack text-white"
                      : ""
                  }`}
                >
                  {value.name}
                </div>
              ))}
          </div>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            handleSearchClick();
          }}
          className="px-4 rounded bg-theblack text-white text-sm"
        >
          Search
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            handleDateFilterToggle();
          }}
          className=" px-4 rounded bg-theblack text-white text-sm "
        >
          {isDateFilter ? "Hide Date Filter" : "Show Date Filter"}
        </button>
      </form>
      {isDateFilter && (
        <div className="flex gap-2 mt-2">
          <input
            type="date"
            ref={startDateRef}
            className="border rounded-lg w-32 h-10 px-3 text-xs text-theblack font-medium focus:outline-none"
            placeholder="Start Date"
          />
          <input
            type="date"
            ref={endDateRef}
            className="border rounded-lg w-32 h-10 px-3 text-xs text-theblack font-medium focus:outline-none"
            placeholder="End Date"
          />
        </div>
      )}
    </div>
  );
};

export default NewSearchBar;
