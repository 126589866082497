import React from "react";
import logo from "../../Assets/logo.png";

const About = () => {
  return (
    <div className="bg-greywhite py-5 my-5">
      <div className="container mx-auto mt-10">
        <h2 className="text-3xl font-medium text-theblack mb-6 text-center">
          Who Are We
        </h2>
        <div className="flex flex-col sm:flex-row items-center sm:items-start ">
          <div className="w-full sm:w-auto  p-5">
            <p className="heading">
              Master Rajpal DSA is a distinguished name in the financial sector,
              renowned for providing a comprehensive range of loan services.
              With a strong presence and well-established roots in offline
              markets, we have consistently demonstrated a commitment to
              excellence. Our seasoned professionals and customer-centric
              approach make us a trusted choice for individuals and businesses
              seeking financial solutions.
            </p>
            <br />
            <p className="heading">
              At Master Rajpal DSA, we specialize in offering a diverse array of
              loan services, catering to the varied needs of our clients.
              Whether it's personal loans, business loans, or other financial
              products, we pride ourselves on delivering tailored solutions with
              competitive terms. Our offline market presence has allowed us to
              build enduring relationships and foster trust within the
              communities we serve.
            </p>
            <br />
            <p className="heading">
              Partnering with Master Rajpal DSA opens the door to a plethora of
              benefits. As a valued associate, you'll tap into our established
              brand reputation and gain access to a wide customer base. Joining
              forces with us means aligning with a company committed to mutual
              growth and success. Enjoy the advantages of a partnership akin to
              those experienced with industry giants like Policy Bazaar.
            </p>
            <br />
            <p className="heading">
              Embark on a rewarding journey with Master Rajpal DSA, where your
              success is intertwined with ours. Together, let's shape the future
              of financial services and create opportunities for prosperity.
              Contact us today to explore the potential of a partnership that
              not only benefits you but also contributes to the financial
              well-being of countless individuals and businesses.
            </p>

            <a
              href="#"
              className="bg-theblack w-max text-white py-2 px-4 rounded-full flex items-center mt-4 hover:scale-105  transition-all ease-in-out duration-700"
            >
              Read More
              <svg
                className="ml-1"
                fill="#FFFFFF"
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
              >
                <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
              </svg>
            </a>
          </div>
          <img
            src={logo}
            alt="About mr rajpaldsa "
            className="aspect-square max-w-[350px] sm:max-w-[400px] w-full sm:w-full blend-overlay"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
