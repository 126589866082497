import React from "react";
import FAQ from "../Services/Faq";
import Footer from "../Home/Footer";

const AboutUs = () => {
  return (
    <>
      <div className="container mx-auto flex flex-col ">
        <h2 className="main-heading mb-6 text-center">Who are we? </h2>
        <div className="bg-greywhite flex flex-col lg:flex-row gap-2 p-5 box-shadow">
          <img
            src="https://img.freepik.com/free-photo/team-working-together-achieve-better-results_1098-3005.jpg?size=626&ext=jpg&ga=GA1.1.2144150192.1700322989&semt=ais"
            alt=""
            className="lg:w-[580px] lg:h-[450px]"
          />
          <div className="flex  flex-col gap-2">
            <h1 className="secondary-heading">
              We Sanction your dreams and give you a Happy Disbursal!
            </h1>
            <p className="heading ml-2">
              MasterRajpal DSA is an innovative one-stop-shop for all your
              financial needs, including Home Loans, Personal Loans, Credit
              Cards, Business Loans, Balance Transfers and more.
              <br />
              <br />
              With a PAN India presence across 2000+ cities, MasterRajpal DSA is
              one of the leading credit lending establishments founded by
              professionals and financial experts who with an experience of 25+
              years have created a rich profile of satisfying the needs of more
              than 10 lakhs+ clients. Our internally developed highly proficient
              Loan Calculator algorithm helps us serve you the best options and
              deals for the loan/financial product of your choice. Our
              algorithmic engines help you by using strong factual data made
              from relative latest changes in the market and a calculated
              understanding of the future trends.
              <br />
              <br />
              Connect with us to help you take your financial decisions right
              and smart.
            </p>
          </div>
        </div>
      </div>

      {/* <div className="container mx-auto mt-10">
        <h2 className="main-heading mb-6 text-center">Our Core Values</h2>

        <div className="flex flex-col items-center gap-6 lg:gap-0 lg:flex-row  lg:justify-between">
          <img
            src="https://img.freepik.com/premium-vector/business-characters-meeting-vector-concept-business-character-office-employee-team_572288-2693.jpg?size=626&ext=jpg&ga=GA1.1.2144150192.1700322989&semt=aisZ"
            alt=""
            className="h-[150px] w-[200px] box-shadow rounded-lg"
          />
          <img
            src="https://img.freepik.com/free-vector/level-up-illustration_23-2150145990.jpg?size=626&ext=jpg&ga=GA1.1.2144150192.1700322989&semt=ais"
            alt=""
            className="h-[150px] w-[200px] box-shadow rounded-lg"
          />
          <img
            src="https://img.freepik.com/free-vector/male-team-illustration_23-2150201042.jpg?size=626&ext=jpg&ga=GA1.1.2144150192.1700322989&semt=ais"
            alt=""
            className="h-[150px] w-[200px] box-shadow rounded-lg"
          />
          <img
            src="https://img.freepik.com/free-vector/plagiarism-concept-with-colleagues-work_23-2148392538.jpg?size=626&ext=jpg&ga=GA1.1.2144150192.1700322989&semt=ais"
            alt=""
            className="h-[150px] w-[200px] box-shadow rounded-lg"
          />
        </div>
      </div> */}
      <FAQ />
    </>
  );
};

export default AboutUs;
