import React, { useState } from "react";
import { ConvertBoolean, StatusTag } from "../Helper/HelperFunctions";

const ListTable = ({
  tableData,
  pagination,
  next,
  prev,
  onNextClick,
  onPrevClick,
  clickabaleOn,
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const [selectedRows, setSelectedRows] = useState([]);

  // const toggleAllRows = () => {
  //   if (selectedRows.length === tableData.tableContent.length) {
  //     setSelectedRows([]);
  //   } else {
  //     setSelectedRows(tableData.tableContent.map((row) => row.id));
  //   }
  // };

  // const toggleRow = (rowId) => {
  //   if (selectedRows.includes(rowId)) {
  //     setSelectedRows(selectedRows.filter((id) => id !== rowId));
  //   } else {
  //     setSelectedRows([...selectedRows, rowId]);
  //   }
  // };

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = () => {
    if (!tableData || !sortConfig.key) return tableData.tableContent;

    return [...tableData.tableContent].sort((a, b) => {
      const keyA = a[sortConfig.key];
      const keyB = b[sortConfig.key];

      if (keyA < keyB) return sortConfig.direction === "ascending" ? -1 : 1;
      if (keyA > keyB) return sortConfig.direction === "ascending" ? 1 : -1;
      return 0;
    });
  };

  return (
    <>
      <div className="container flex flex-col mx-auto">
        <div
          className="relative overflow-x-auto box-shadow w-full mt-6"
          style={{ height: "max-content" }}
        >
          <table className="w-full text-left z-[0] ">
            <thead className="font-light text-[14px] text-[#3e3e3e] bg-[#fcfcfc] border border-borderColor/20">
              <tr>
                <th className="px-2 py-3">
                  <div className="flex items-center cursor-pointer hover:text-theblack/50 font-light text-[14px]">
                    {/* <input
                      type="checkbox"
                      checked={
                        selectedRows.length === tableData.tableContent.length
                      }
                      onChange={toggleAllRows}
                      style={{ color: "#3e3e3e" }}
                    /> */}
                  </div>
                </th>
                {tableData &&
                  tableData.tableHeaders.map((data, key) => {
                    return (
                      <th
                        scope="col"
                        className="px-2 py-3"
                        key={key}
                        onClick={() => requestSort(data)}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="flex items-center cursor-pointer hover:text-theblack/80">
                          {data}
                          <svg
                            className="w-3 h-3 ms-1.5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                          </svg>
                        </div>
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {sortedData().map((data, index) => {
                return (
                  <tr
                    key={index}
                    className={`${
                      index % 2 !== 0 ? "bg-[#f9f9f9]" : "bg-white"
                    } border-b`}
                  >
                    <td className="px-2 py-4">
                      <div className="flex items-center cursor-pointer">
                        {/* <input
                          type="checkbox"
                          checked={selectedRows.includes(data.id)}
                          onChange={() => toggleRow(data.id)}
                          style={{ color: "#3e3e3e" }}
                        /> */}
                      </div>
                    </td>
                    {data &&
                      tableData.tableHeaders.map((value, key) => {
                        return (
                          <td
                            className={`px-2 py-4 text-[14px] font-normal ${
                              value === "Name"
                                ? "text-theblack font-semibold"
                                : "text-[#7d8398]"
                            }`}
                            key={key}
                          >
                            {clickabaleOn && clickabaleOn.on.includes(value) ? (
                              <div
                                className="text-theblack hover:text-theblack/80 cursor-pointer"
                                onClick={() => {
                                  clickabaleOn.funct[value](data);
                                }}
                              >
                                {data[value] || "--"}
                              </div>
                            ) : value === "Status" ? (
                              StatusTag(data[value])
                            ) : (
                              data[value] || "--"
                            )}
                          </td>
                        );
                      })}
                    {/* {actions && (
                      <td className="px-2 py-4  ">
                        {actions.view && (
                          <button
                            onClick={() => onView(data)}
                            className="font-medium text-yellow-600 hover:text-yellow-500 px-2"
                          >
                            View
                          </button>
                        )}

                        {actions.edit && (
                          <button
                            onClick={() => onEdit(data)}
                            className="font-medium text-yellow-600 hover:text-yellow-500 px-2"
                          >
                            Edit
                          </button>
                        )}

                        {actions.delete && (
                          <button
                            onClick={() => onDelete(data)}
                            className="font-medium text-red-600 hover:text-red-400 px-2"
                          >
                            Delete
                          </button>
                        )}
                        {customButton?.show && (
                          <button
                            onClick={() => customButton.customClick(data)}
                            className={`${
                              ConvertBoolean(data[customButton.mapby])
                                ? "text-gray-600 hover:text-gray-400"
                                : "text-green-600 hover:text-green-400"
                            } font-medium `}
                          >
                            {ConvertBoolean(data[customButton.mapby])
                              ? customButton.name[1]
                              : customButton.name[0]}
                          </button>
                        )}
                      </td>
                    )} */}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {pagination && (
            <div className="flex items-center justify-end p-2 ">
              <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                <li>
                  <button
                    disabled={!prev}
                    onClick={onPrevClick}
                    className={`flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-theblack hover:text-white ${
                      !prev ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    Previous
                  </button>
                </li>
                <li>
                  <button
                    onClick={onNextClick}
                    disabled={!next}
                    className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-theblack hover:text-white ${
                      !next ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ListTable;
