import React from "react";
import instagram from "../../Assets/Icons/instagram.png";
import facebook from "../../Assets/Icons/facebook.png";
import youtube from "../../Assets/Icons/youtube.png";
import linkedin from "../../Assets/Icons/linkedin.png";
import twitter from "../../Assets/Icons/twitter.png";

const ContactUs = () => {
  const makePhoneCall = () => {
    window.open("tel:+91 9310069549");
  };

  return (
    <>
      <div className="container mx-auto flex flex-col box-shadow py-4">
        <h2 className="text-3xl font-semibold lg:main-heading mb-6 text-center ">
          Contact Us
        </h2>
        <div className="flex flex-col lg:flex-row gap-5 px-4">
          <p className="mt-5  text-justify ">
            MasterRajPal DSA stands as a trusted and versatile financial
            institution, dedicated to facilitating comprehensive loan provisions
            for individuals and businesses. Founded with a commitment to
            addressing diverse financial needs, we specialize in an extensive
            spectrum of loan offerings, including personal loans, business
            funding, home mortgages, and other tailored financial solutions. Our
            approach is centered on providing accessible and reliable lending
            options, leveraging a seamless application process, competitive
            interest rates, and personalized assistance to ensure our clients
            receive the financial support they require promptly and efficiently.
            <br />
            <br />
            Moreover, MasterRajPal DSA champions innovation and technological
            integration within the lending sphere, continually seeking to refine
            and elevate our services. By staying abreast of market trends and
            leveraging cutting-edge tools, we ensure a forward-thinking approach
            that keeps us at the forefront of the financial industry. Our
            unwavering dedication to excellence, combined with a genuine passion
            for empowering individuals and businesses, distinguishes
            MasterRajPal DSA as the go-to destination for reliable, trustworthy,
            and client-focused financial solutions.
            <br />
            <br />
            With a customer-centric ethos at our core, MasterRajPal DSA prides
            itself on fostering enduring relationships by prioritizing
            transparency, integrity, and responsiveness. Our team comprises
            seasoned financial experts who not only comprehend the nuances of
            the lending landscape but also strive to navigate our clients
            through their financial journey with expertise and empathy. Whether
            it's facilitating quick approvals or offering guidance through
            complex financial decisions, we stand committed to delivering
            superior service that aligns with the diverse aspirations and goals
            of our clientele. Please note that we exclusively specialize in
            loans and do not offer insurance services.
          </p>
          <div className="w-full hidden lg:block">
            <img
              className="w-full h-auto object-cover object-center "
              src="https://img.freepik.com/free-vector/staff-management-perspective-definition-target-orientation-teamwork-organization-business-coach-company-executive-personnel-cartoon-characters_335657-2967.jpg?size=626&ext=jpg&ga=GA1.1.923863604.1702044673&semt=ais"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className=" container mx-auto flex flex-col gap-2 mt-6 mb-6">
        <h1 className="text-xs font-medium capitalize text-logo-blue border-b-2 border-logo-orange max-w-max ">
          Disclaimer
        </h1>
        <p className=" text-xs font-normal  text-gray-400">
          The information provided by MasterRajPal DSA is for general
          informational purposes only and should not be considered as financial
          advice. While we strive to ensure the accuracy and reliability of the
          information presented, we do not warrant or guarantee the
          completeness, suitability, or availability of our services for any
          particular purpose. Clients are advised to seek professional financial
          guidance and thoroughly review all terms and conditions before making
          any financial decisions. MasterRajPal DSA shall not be held liable for
          any losses or damages arising from the use of our services or reliance
          on the information provided.
        </p>
      </div>
      <div className="container mx-auto flex flex-col lg:flex-row justify-between mt-10  mb-6 gap-8 lg:gap-0">
        <div className="w-full lg:w-[33%] h-40 box-shadow bg-white flex items-center justify-center  hover:bg-theblack hover:translate-y-[-5px] transition-all duration-500 ease-in-out">
          <div className="flex flex-col gap-2 justify-center items-center">
            <p className="text-center font-normal text-theblack text-3xl ">
              Call Us
            </p>
            <a
              className="text-center font-normal text-white text-lg cursor-pointer"
              onClick={makePhoneCall}
            >
              +91 9310069549
            </a>
          </div>
        </div>
        <div className="w-full lg:w-[33%] h-40 box-shadow bg-white flex items-center justify-center  hover:bg-theblack hover:translate-y-[-5px] transition-all duration-500 ease-in-out">
          <div className="flex flex-col gap-2 justify-center items-center">
            <p className="text-center font-normal text-theblack text-3xl  ">
              Email Us
            </p>
            <a
              className="text-center font-normal text-white text-lg  cursor-pointer"
              href="mailto:info@masterrajpaldsa.com"
              title="send email"
              target="_blank"
            >
              info@masterrajpaldsa.com
            </a>
          </div>
        </div>
        <div className="w-full lg:w-[33%] h-40 box-shadow bg-white flex items-center justify-center  hover:bg-theblack hover:translate-y-[-5px] transition-all duration-500 ease-in-out">
          <div className="flex flex-col gap-2 justify-center items-center">
            <p className="text-center font-normal text-theblack text-3xl ">
              Connect with us
            </p>
            <div className="flex  flex-row justify-between gap-4 items-center mt-2">
              <a
                href="https://www.instagram.com/masterrajpaldsapvtltd/"
                target="_blank"
                className="h-full w-full"
              >
                <img className="h-10 aspect-square" src={instagram} alt="" />
              </a>
              <a
                href="https://www.facebook.com/MASTERRAJPALDSAPVTLTD"
                target="_blank"
                className="h-full w-full"
              >
                <img className="h-10 aspect-square" src={facebook} alt="" />
              </a>
              <a
                href="https://www.youtube.com/@masterrajpaldsapvtltd"
                target="_blank"
                className="h-full w-full"
              >
                <img className="h-10 aspect-square" src={youtube} alt="" />
              </a>
              <a
                href="https://twitter.com/masterrajpaldsa"
                className="h-full w-full"
                target="_blank"
              >
                <img className="h-10 aspect-square" src={twitter} alt="" />
              </a>
              <a
                href="https://in.linkedin.com/in/masterrajpaldsapvtltd"
                target="_blank"
                className="h-full w-full"
              >
                <img className="h-10 aspect-square" src={linkedin} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
